import React, { FC, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import useCompany from 'hooks/api/useCompany';
import pencilSvg from 'assets/img/newPencil.svg';
import delSvg from 'assets/img/newDelete.svg';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import ConfirmationDeleteModal from 'components/common/ConfirmationDeleteModal';
import { deleteCompanyById } from 'api/company';
import { Button, Modal, notification } from '@gamesb42/ui-kit';
import { deleteUser } from 'api/users';
import { getToken } from 'helpers/token';
import Flex from 'components/Flex';

type PropsT = {
  name: string;
  role: string;
};

const ActionButtonCard: FC<PropsT> = ({ name, role }) => {
  const history = useHistory();
  //@ts-ignore
  const { userId } = useParams();

  const restoreUser = (userId: string) => {
    fetch(`${process.env.REACT_APP_SCA_URL}/v1/users/${userId}/restore`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const getButtonList = () => {
    const buttonList = [
      {
        image: pencilSvg,
        title: 'Edit User',
        style: { color: '#838998' },
        onClick: () => history.push(`/admins/edit-admin/${userId}`),
      },
      {
        image: delSvg,
        title: 'Delete User',
        style: { color: '#EC3E72' },
        onClick: () =>
          Modal.warning({
            title: 'Delete Admin',
            okButtonProps: {
              style: { background: '#EC3E72', border: 'none' },
            },
            okText: 'Delete',
            content: `Are you shure you want to delete ${role} ${name}?`,
            onOk: () =>
              deleteUser(userId)
                .then(() => {
                  notification.warning({
                    theme: 'dark',
                    message: (
                      <Flex gap="10px" direction="column">
                        <div>{`${role} ${name} was deleted`}</div>
                        <Button style={{ alignSelf: 'flex-end' }} onClick={() => restoreUser(userId)}>
                          Restore
                        </Button>
                      </Flex>
                    ),
                    placement: 'bottomLeft',
                  });
                })
                .catch(() => {})
                .finally(() => {
                  history.push(urls.getAdmins());
                }),
            onCancel: () => {},
          }),
      },
    ];

    return buttonList;
  };

  return (
    <div>
      <ViewGeneralActions buttonList={getButtonList()} />
    </div>
  );
};

export default ActionButtonCard;
