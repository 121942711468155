import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import urls from 'constants/urls';
import useRouteTitle from 'hooks/useRouteTitle';
import { UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';

import styles from './styles.module.scss';
import Filters from './Filters';
import { useUserContext } from 'contexts/UserProvider';

const plusSvg = require('assets/img/plus.svg').default;

const PurchaseRequestTableHeader: FC | any = () => {
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const { t } = useTranslation();
  const { title } = useRouteTitle();
  const history = useHistory();
  const { user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <div>
          {isAdmin && (
            <Button
              style={{ height: 40 }}
              {...dropZoneStylingButton}
              onClick={() => {
                history.push(urls.getPurchaseRequestCreate());
              }}
              startIcon={<img className="dropZoneImg" src={plusSvg} alt="plus" />}
            >
              {t('tables.purchaseRequest.createRequest')}
            </Button>
          )}
        </div>
      </div>
      <Filters />
    </div>
  );
};

export default PurchaseRequestTableHeader;
