import React, { FC } from 'react';

import { KeyCardsEnum } from 'components/forms/ActivationOrderForm/CardsAo/data';

import { paramsObjAO } from '../../ActivationOrderForm';
import ItemsAo from '../ItemsAo';

interface CardFutureReceivablesType {
  params?: paramsObjAO;
}

const CardFuture: FC<CardFutureReceivablesType> = React.memo(() => {
  return (
    <>
      <ItemsAo types={[KeyCardsEnum.AO_DATE, KeyCardsEnum.AO_NUMBER]} />
      <ItemsAo types={[KeyCardsEnum.PENALTY_POLICY, KeyCardsEnum.CURRENCY]} />
      <ItemsAo types={[KeyCardsEnum.AMOUNT, KeyCardsEnum.PURCHASED_RECEIVABLES]} />
      <ItemsAo types={[KeyCardsEnum.PERIOD, KeyCardsEnum.PURCHASE_DATE]} />
      <ItemsAo types={[KeyCardsEnum.FIRST_REPAYMENT_DATE, KeyCardsEnum.FINAL_REPAYMENT_DATE]} />

      <ItemsAo types={[KeyCardsEnum.DISTRIBUTION_PLATFORMS_FA_CREATE]} />
      <ItemsAo types={[KeyCardsEnum.PAYMENT_PLAN]} />

      <ItemsAo types={[KeyCardsEnum.AO_FUTURE_CUSTOM_SETTINGS]} />
    </>
  );
});

export default CardFuture;
