// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import usePurchaseRequest, {
  PurchaseRequestDataType,
  PurchaseRequestStatusToNumber,
} from 'hooks/api/usePurchaseRequest';
import Item, { getCurrencyWithAmount, TypeEnum } from 'components/common/GeneralView/Item';
import Card from 'components/common/GeneralView/Card';
import { GeneralEnum, PlatformEnum, PlatformTableConfig } from 'components/forms/PurchaseRequestForm/data';
import { formatAmount, formatDate, formatDateIsoShort, getCurrency } from 'helpers/formatters';
import urls from 'constants/urls';
import Accordion from 'components/uiKit/Accordion';
import minusIcon from 'assets/img/minus-black.svg';
import plusIcon from 'assets/img/plus-black.svg';
import Status, { StatusEnumTitle } from 'components/common/Status';
import { CurrencyType } from 'components/uiKit/InputKit';

import Tooltip from '../../../../../Tooltip';
import useCompany, { ClaimsType, useCompanyType } from '../../../../../../hooks/api/useCompany';
import CreateTransactionCompanyPopUp from '../../../../CompanyForm/View/PopUpsCompany/CreateTransactionCompanyPopUp/CreateTransactionCompanyPopUp';

import styles from './styles.module.scss';

const claimsArrow = require('assets/img/claimsArrow.svg').default;

type PropsT = {
  purchaseRequestData: PurchaseRequestDataType;
};

const GeneralInformation: FC<PropsT> = ({ purchaseRequestData }) => {
  const wasFunded =
    Number(PurchaseRequestStatusToNumber[purchaseRequestData[GeneralEnum.STATUS]]) >=
    Number(PurchaseRequestStatusToNumber.funded);
  const isHasAttachedDocument = Boolean(purchaseRequestData.file_id);
  const { t } = useTranslation();
  const [hoverEl, setHoverEl] = useState(null);
  const [currentClaim, setCurrentClaim] = useState<ClaimsType | false>(false);
  const [disabled, setIsDisabled] = useState(false);
  const { getPurchaseRequest } = usePurchaseRequest();
  const { createTransactionInToClaim }: useCompanyType = useCompany();

  const isNotFundedStatus =
    purchaseRequestData.status === 'pending' ||
    purchaseRequestData.status === 'new' ||
    purchaseRequestData.status === 'rejected' ||
    purchaseRequestData.status === 'signed';

  return (
    <>
      {currentClaim && (
        <CreateTransactionCompanyPopUp
          isOpen={!!currentClaim}
          onClose={() => setCurrentClaim(false)}
          disabled={disabled}
          leftCallback={({ date, amount }) => {
            setIsDisabled(true);
            createTransactionInToClaim({
              body: { date: formatDateIsoShort(date), purchaseRequestClaimId: currentClaim.id, amount },
              successCallback: () => {
                setTimeout(() => getPurchaseRequest({ id: purchaseRequestData.id }), 1000);
                setCurrentClaim(false);
                setIsDisabled(false);
              },
              errorCallback: () => {
                setIsDisabled(false);
                setCurrentClaim(false);
              },
            });
          }}
          rightCallback={() => setCurrentClaim(false)}
          firstCellContent={purchaseRequestData[GeneralEnum.REQUEST]}
          secondCellContent={currentClaim.name}
          thirdCellContent={`${getCurrency(currentClaim.currency)}${formatAmount(Number(currentClaim.outstanding))}`}
          currency={currentClaim.currency as CurrencyType}
          companyId={purchaseRequestData.company_id}
        />
      )}
      <div className={styles.blockWithTwoCard}>
        <Card>
          <Item
            type={TypeEnum.HREF}
            title="forms.purchaseRequest.company"
            value={purchaseRequestData[GeneralEnum.COMPANY]}
            additionalStyle={{ textAlign: 'left', textDecoration: 'underline' }}
            fileProps={{
              link: `${urls.getCompany()}/${purchaseRequestData[GeneralEnum.COMPANY_ID]}`,
            }}
          />
          <Item
            type={TypeEnum.HREF}
            title="forms.purchaseRequest.ao"
            value={purchaseRequestData[GeneralEnum.AO]}
            additionalStyle={{ borderBottom: '1px solid #000' }}
            fileProps={{
              link: `${urls.getActivationOrder()}/${purchaseRequestData[GeneralEnum.AO_ID]}?type=available_receivables`,
            }}
          />
          <Item
            type={TypeEnum.TEXT}
            title="forms.purchaseRequest.request"
            value={purchaseRequestData[GeneralEnum.REQUEST]}
          />
          <Item
            type={TypeEnum.TEXT}
            title="forms.purchaseRequest.request_date"
            value={formatDate(purchaseRequestData[GeneralEnum.PR_DATE])}
          />
          {purchaseRequestData[GeneralEnum.STATUS] === 'repaid' && (
            <Item
              type={TypeEnum.TEXT}
              title="forms.purchaseRequest.repayment_date"
              value={formatDate(purchaseRequestData[GeneralEnum.REPAYMENT_DATE])}
            />
          )}
        </Card>
        <Card>
          <Item
            type={TypeEnum.TEXT}
            title="forms.purchaseRequest.purchase_date"
            value={(wasFunded && formatDate(purchaseRequestData[GeneralEnum.PURCHASE_DATE])) || '-'}
            wrapStyle={{ alignItems: 'center' }}
          />
          <Item
            wrapStyle={{ whiteSpace: 'nowrap' }}
            type={TypeEnum.TEXT}
            title="forms.purchaseRequest.available_receivables"
            value={purchaseRequestData.platforms.reduce((acc, curr) => {
              return acc + Number(curr.ar);
            }, 0)}
            currency={purchaseRequestData[GeneralEnum.CURRENCY]}
            valueFormatType="money"
          />
          <Item
            wrapStyle={{ whiteSpace: 'nowrap' }}
            type={TypeEnum.TEXT}
            title="forms.purchaseRequest.purchased_receivables"
            value={purchaseRequestData[GeneralEnum.PURCHASED_RECEIVABLES]}
            currency={purchaseRequestData[GeneralEnum.CURRENCY]}
            valueFormatType="money"
          />
          <Item
            type={wasFunded ? TypeEnum.HREF : TypeEnum.TEXT}
            title="forms.purchaseRequest.purchase_price"
            value={purchaseRequestData[GeneralEnum.PURCHASE_PRICE]}
            additionalStyle={{
              borderBottom: wasFunded ? '1px solid #000' : undefined,
            }}
            fileProps={{
              link: `${urls.getTransactions()}/${purchaseRequestData[GeneralEnum.TRANSACTION_TS]}`,
            }}
            currency={purchaseRequestData[GeneralEnum.CURRENCY] || 'EUR'}
            valueFormatType="money"
          />
          <Item
            type={TypeEnum.TEXT}
            title="forms.purchaseRequest.outstanding"
            value={purchaseRequestData[GeneralEnum.OUTSTANDING] || '-'}
            currency={purchaseRequestData[GeneralEnum.CURRENCY] || 'EUR'}
            valueFormatType={purchaseRequestData[GeneralEnum.OUTSTANDING] ? 'money' : undefined}
          />
        </Card>
      </div>
      <Card>
        <>
          <Item
            type={TypeEnum.CAPTION}
            content={(() => (
              <div className={styles.tableRow}>
                {Children.toArray(
                  PlatformTableConfig.map((item) => {
                    return (
                      item.field?.length > 0 && (
                        <div
                          className={styles.title}
                          style={{ borderBottom: '1px solid #EEE', textAlign: item.align || 'inherit' }}
                        >
                          <span>{t(`forms.purchaseRequest.${item.field}`)}</span>
                        </div>
                      )
                    );
                  }),
                )}
              </div>
            ))()}
          />
          {Children.toArray(
            purchaseRequestData.platforms.map((platform, i) => (
              <Item
                type={TypeEnum.TEXT}
                content={(() => (
                  <div className={styles.tableRow}>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '24px 1fr',
                        alignItems: 'center',
                        gap: 12,
                        textAlign: 'left',
                      }}
                    >
                      <img
                        src={platform[PlatformEnum.platform_logo]}
                        alt="ico"
                        style={{ paddingRight: 12, width: 24 }}
                      />
                      {platform[PlatformEnum.platform]}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      {getCurrencyWithAmount(
                        platform[PlatformEnum.ar],
                        purchaseRequestData[GeneralEnum.CURRENCY] || 'EUR',
                      )}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      {platform.period_from ? moment(platform.period_from, 'YYYY-MM-DD').format('DD-MM-YY') : ''}
                      {platform.period_from && platform.period_to ? (
                        <>
                          <br />
                          <span>-</span>
                        </>
                      ) : (
                        <span>-</span>
                      )}
                      {platform.period_to ? moment(platform.period_to, 'YYYY-MM-DD').format('DD-MM-YY') : ''}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      {getCurrencyWithAmount(
                        platform[PlatformEnum.purchased],
                        purchaseRequestData[GeneralEnum.CURRENCY] || 'EUR',
                      )}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      {getCurrencyWithAmount(
                        platform[PlatformEnum.purchase_price],
                        purchaseRequestData[GeneralEnum.CURRENCY] || 'EUR',
                      )}
                    </div>
                    <div style={{ textAlign: 'right' }}>{formatDate(platform[PlatformEnum.due_date])}</div>
                    <div style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <div
                          style={{ display: 'inline-flex', justifyContent: 'end', alignItems: 'center' }}
                          onMouseEnter={() => {
                            setHoverEl(i);
                          }}
                          onMouseLeave={() => setHoverEl(null)}
                        >
                          {!!Number(platform[PlatformEnum.outstanding]) &&
                            !isNotFundedStatus &&
                            purchaseRequestData[GeneralEnum.STATUS] !== 'repaid' && (
                              <>
                                <span style={{ marginRight: 4 }}>
                                  {`${getCurrency(purchaseRequestData[GeneralEnum.CURRENCY])}${formatAmount(
                                    Number(platform[PlatformEnum.outstanding]),
                                  )}`}
                                </span>
                                <Tooltip
                                  isShow={hoverEl === i}
                                  tooltipWithoutArrow
                                  title="Pay off"
                                  styleTooltip={{ width: 'max-content' }}
                                >
                                  <img
                                    onClick={() =>
                                      setCurrentClaim({
                                        available_receivables: platform.ar,
                                        name: platform.platform,
                                        id: platform.claim_id,
                                        logo: platform.platform_logo,
                                        outstanding: platform.outstanding,
                                        currency: platform.currency,
                                        purchase_price: platform.purchase_price,
                                        purchased: platform.purchased,
                                        pr_status: purchaseRequestData.status,
                                        expected_date: platform.expected_date,
                                      })
                                    }
                                    style={{ width: 24, display: 'inline' }}
                                    src={claimsArrow}
                                    alt="claimsArrow"
                                  />
                                </Tooltip>
                              </>
                            )}
                          {(!Number(platform[PlatformEnum.outstanding]) ||
                            isNotFundedStatus ||
                            purchaseRequestData[GeneralEnum.STATUS] === 'repaid') && (
                            <Status
                              style={{ width: 88 }}
                              status={isNotFundedStatus ? StatusEnumTitle.NOT_FUNDED : StatusEnumTitle.REPAID}
                              newColors={isNotFundedStatus ? {} : { backgroundColor: '#E9F7FA', color: '#1EA7C6' }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))()}
              />
            )),
          )}
        </>
      </Card>
      {purchaseRequestData.comment && (
        <Accordion
          initialState
          title={t('forms.purchaseRequest.rejectionReason')}
          closeImg={<img src={minusIcon} alt="close rejection reason" />}
          openImg={<img src={plusIcon} alt="open rejection reason" />}
          className={styles.accordionButton}
        >
          <Card>
            <Item
              additionalStyle={{ wordBreak: 'break-word' }}
              type={TypeEnum.TEXT}
              title="forms.purchaseRequest.rejectionReason"
              value={purchaseRequestData.comment}
            />
          </Card>
        </Accordion>
      )}
      {isHasAttachedDocument && (
        <Accordion
          initialState
          title={t('forms.purchaseRequest.attachedFilesF')}
          closeImg={<img src={minusIcon} alt="close attached files" />}
          openImg={<img src={plusIcon} alt="open attached files" />}
          className={styles.accordionButton}
        >
          <div className={styles.accordionContent}>
            <Card>
              <Item
                type={TypeEnum.FILE}
                title="forms.purchaseRequest.attachedFiles"
                fileProps={{
                  isFileStile: true,
                  link: purchaseRequestData.file_id,
                  name: `${t('forms.purchaseRequest.request')} ${
                    purchaseRequestData[GeneralEnum.REQUEST]
                  } dated ${formatDate(purchaseRequestData[GeneralEnum.PR_DATE])} ${
                    purchaseRequestData[GeneralEnum.STATUS] === 'signed' ? 'signed' : ''
                  }`,
                }}
              />
            </Card>
          </div>
        </Accordion>
      )}
    </>
  );
};

export default GeneralInformation;
