import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Modal,
  notification,
  Upload,
  DatePicker,
  InputAmount,
  InputNumber,
  DatePickerProps,
} from '@gamesb42/ui-kit';
import { UploadFile } from 'antd';
import Flex from 'components/Flex';
import { getToken } from 'helpers/token';
import { convertFileToBase64 } from 'helpers/file';

interface IEditModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  agreementId: string;
}

export default function EditModal({ open, setOpen, agreementId }: IEditModal) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<DatePickerProps['value'] | string>(null);
  const [numberFA, setNumberFA] = useState<Number>();

  const handleUpload = async () => {
    setLoading(true);
    const fileRaw = await convertFileToBase64(fileList[fileList.length - 1]);

    //@ts-ignore
    const fileSplit = fileRaw?.split(',');
    const raw = JSON.stringify({
      file_content: fileSplit[1],
      file_name: fileList[fileList.length - 1].name,
      agreement_date: date,
      agreement_number: numberFA?.toString(),
    });

    const requestOptions = {
      method: 'PATCH',
      headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': 'application/json' },
      body: raw,
    };

    await fetch(`${process.env.REACT_APP_BASE_URL}/agreement/${agreementId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  const beforeUpload = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result as string;
      setFileContent(content);
      setFileList([...fileList, file]);
    };
    reader.readAsText(file);

    return false;
  };

  return (
    <Modal
      open={open}
      title="Edit Factoring Agreement"
      okText="Save"
      onOk={handleUpload}
      okButtonProps={{}}
      cancelText="Cancel"
      onCancel={() => setOpen(false)}
      width="582px"
    >
      <Flex direction="column" gap="32px">
        <Flex justify="space-between">
          <div>
            <DatePicker
              onChange={(e) => {
                if (e) {
                  //@ts-ignore
                  setDate(e.format('YYYY-MM-DD'));
                }
              }}
              label="FA date"
              size="large"
              format="DD.MM.YYYY"
              style={{ width: '251px' }}
              // aria-required
            />
          </div>
          <InputNumber
            label="FA number"
            size="large"
            style={{ width: '251px' }}
            min={0}
            max={100}
            // @ts-ignore
            onChange={(e) => setNumberFA(e)}
          />
        </Flex>

        <Upload beforeUpload={beforeUpload} maxCount={1} title="Upload new Factoring Agreement" />
      </Flex>
    </Modal>
  );
}
