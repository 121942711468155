// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import useActivationOrder, { activationOrderDataType, AoTypeEnum } from 'hooks/api/useActivationOrder';
import endpoints from 'consts/endpoints';
import Modal from 'helpers/PopUpLayout';
import { getToken } from 'helpers/token';
import { convertFileToBase64 } from 'helpers/file';
import { formatDateIsoShort } from 'helpers/formatters';
import { PurchaseRequestSetStatusT } from 'queries/purchaseRequest/setNewStatus';
import { PurchaseRequestDataType, PurchaseRequestStatus } from 'hooks/api/usePurchaseRequest';
import { UseStylingButton, UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import DateInput from 'components/inputs/DateInput';
import Textarea from 'components/uiKit/TextareaKit';
import { AmountKit } from 'components/uiKit/AmountKit';
import DropZoneDocument from 'components/forms/CompanyForm/DropZoneDocument';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import CheckboxKit from 'components/uiKit/CheckboxKit';

import { MAP_TYPE_TO_INITIAL_VALUE, MAP_TYPE_TO_BUTTON_SUBMIT_TEXT, TypeAllValues } from './data';
import styles from './styles.module.scss';
import { FormLayout } from '@gamesb42/ui-kit';
import FactorAccountsSelect from 'components/FactorAccountsSelect';

type PropsT = {
  isOpen: boolean;
  onClose: () => void;
  setStatus?: any;
  activationOrderId?: string;
  setLink?: any;
};

const UploadAOChangeStatus: FC<PropsT> = ({ isOpen, onClose, setStatus, activationOrderId, setLink }) => {
  const { t } = useTranslation();
  const stylingButton = UseStylingButton();
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const [file, setFile] = useState<File[]>([]);
  const [fileUrl, setFileUrl] = useState();
  const { getActivationOrder } = useActivationOrder();

  const handleDropZoneChange = async (access: File[]) => {
    if (access.length === 0) {
      setFile(access);
    }

    if (access.length > 0) {
      const fileUrlRaw: string = (await convertFileToBase64(access[0])) as string;
      const fileUrlClear = fileUrlRaw.replace('data:application/pdf;base64,', '');

      setFileUrl(fileUrlClear);
      setFile(access);
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      const myHeaders = new Headers();
      myHeaders.append('authorization', `Bearer ${getToken()}`);
      myHeaders.append('content-type', 'application/json');
      const raw = JSON.stringify({
        content: fileUrl,
        file_name: file[0].name,
      });
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      };

      const result = await fetch(
        `${process.env.REACT_APP_BASE_URL}/activation_order/${activationOrderId}/upload_file`,
        requestOptions,
      );
      const resultJSON = await result.json();

      setStatus('active');
      setLink(resultJSON?.ao_document?.link);
    } catch (e) {
    } finally {
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <div className={styles.paper}>
          <div className={styles.wrapperSigned}>
            <DropZoneDocument
              additionalClassNameForContent={styles.dropZoneContent}
              title={t('forms.purchaseRequest.changeStatusModal.signed.titleFile')}
              text={t('forms.purchaseRequest.changeStatusModal.signed.additionalText')}
              buttonText={t('forms.purchaseRequest.changeStatusModal.signed.titleButton')}
              setAccessFiles={handleDropZoneChange}
              accessFiles={file}
            />
          </div>
          <div className={styles.actionWrapper}>
            <Button type="submit" style={{ padding: '17px 19px', lineHeight: '24px', fontSize: 16 }} {...stylingButton}>
              Save file
            </Button>
            <Button
              type="button"
              onClick={onClose}
              style={{ padding: '17px 19px', lineHeight: '24px', fontSize: 16 }}
              {...dropZoneStylingButton}
            >
              {t('forms.purchaseRequest.changeStatusModal.cancel')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default UploadAOChangeStatus;
