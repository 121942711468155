//@ts-nocheck
import React, { FC, useEffect, useCallback, useState, useMemo, MouseEvent } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import { formatDate } from 'helpers/formatters';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import { typeAo } from 'components/forms/ActivationOrderForm/CardsAo/data';
import { AoTypeEnum } from 'hooks/api/useActivationOrder';
import downloadSvg from 'assets/img/download.svg';
import urls from 'constants/urls';

import Flex from 'components/Flex';
import classNames from 'classnames';
import Select from 'components/uiKit/Select';
import styles from './styles.module.scss';
import endpoints from 'consts/endpoints';
import { getFileStorage } from '../../reportsData';
import { useUserContext } from 'contexts/UserProvider';

import s from './styles.module.scss';
import { AddIcon, Button, DownloadIcon, PencilIcon, Table, Tooltip } from '@gamesb42/ui-kit';
import { getToken } from 'helpers/token';
import { Dropdown, Menu } from 'antd';
import Status, { StatusEnumTitle } from 'components/common/Status';

type PropsT = {
  companyId: string;
};

const AOClient: FC<PropsT> = ({ companyId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { getAOByCompany, aOByCompanyData }: useCompanyType = useCompany();
  const [currentProductFilter, setCurrentProductFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [documentStatus, setDocumentStatus] = useState('');
  const { logOut, user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  const columns = [
    {
      title: 'Name',
      width: '198px',
      align: 'left' as 'left',
      render: (row: any) => (
        <span
          onClick={() => history.push(`${urls.getActivationOrder()}/${row.id}`)}
          style={{ color: '#7145EC', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {row.name}
        </span>
      ),
    },
    {
      title: 'Type',
      width: '196px',
      align: 'left' as 'left',
      render: (row: any) => <>{typeAo.find((type) => type.key === row.ao_type)?.name}</>,
    },
    {
      title: 'Signing',
      width: '159px',
      align: 'center' as 'center',
      render: (row: any) => {
        return row.files.length > 0 ? <Flex>{renderSigningStatus(row.files[0].status)}</Flex> : <></>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '211px',
      align: 'center' as 'center',
      render: (status: string) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Status style={{ width: '100%' }} status={status} />
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      align: 'left' as 'left',
      render: (row: any) => {
        return (
          <div style={{ display: 'flex', height: '100%', gap: '32px', alignItems: 'center' }}>
            <Tooltip title="Edit">
              <Button
                style={{
                  visibility: ['ACTIVE', 'NEW'].includes(row.status) ? 'visible' : 'hidden',
                }}
                type="iconBtn"
                size="middle"
                onClick={() => history.push(`${urls.getActivationOrder()}/edit/${row.ao_type}/${row.id}`)}
                icon={<PencilIcon />}
              />
            </Tooltip>
            {row.files[0]?.id && (
              <Tooltip title="Download">
                <Button
                  type="iconBtn"
                  icon={<DownloadIcon size={16} />}
                  size="middle"
                  onClick={(e: any) => downloadPdf(e, row.files[0]?.id)}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setCompany();
  }, []);

  const setCompany = useCallback(() => {
    companyId && getAOByCompany({ company_id: companyId });
  }, [companyId]);

  const getPdf = (fileId?: string, openPdf: boolean = false) => {
    if (!fileId) return;
    getFileStorage(endpoints.getStorageFileUrl(fileId), undefined, undefined, undefined, openPdf);
  };

  const downloadPdf = (e: MouseEvent<HTMLImageElement>, fileId?: string) => {
    e.stopPropagation();
    getPdf(fileId);
  };

  const preparedAOByCompanyData = useMemo(() => {
    if (!currentProductFilter) return aOByCompanyData;
    return aOByCompanyData.filter((ao) => ao.ao_type === currentProductFilter);
  }, [aOByCompanyData, currentProductFilter]);

  useEffect(() => {
    if (preparedAOByCompanyData.length > 0) {
      const myHeaders = new Headers();
      myHeaders.append('authorization', `Bearer ${getToken()}`);

      fetch(`${process.env.REACT_APP_BASE_URL}/activation_order/${preparedAOByCompanyData[0]?.id}/status`, {
        method: 'GET',
        headers: myHeaders,
      })
        .then((response) => response.json())
        .then((result) => setDocumentStatus(result.document_status))
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [preparedAOByCompanyData]);

  function renderSigningStatus(status: string) {
    switch (status) {
      case 'sent':
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '24px',
              borderRadius: '4px',
              background: '#9FA5B9',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
            }}
          >
            SENT
          </div>
        );
      case 'created':
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '24px',
              borderRadius: '4px',
              background: '#95B3FF',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
            }}
          >
            CREATED
          </div>
        );
      case 'signed':
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '24px',
              borderRadius: '4px',
              background: '#95B3FF',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
            }}
          >
            SIGNED BY CLIENT
          </div>
        );
      case 'delivered':
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '24px',
              borderRadius: '4px',
              background: '#95B3FF',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
            }}
          >
            DELIVERED
          </div>
        );
      case 'completed':
        return (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '24px',
              borderRadius: '4px',
              background: '#32D141',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
            }}
          >
            COMPLETED
          </div>
        );
      default:
        return <></>;
    }
  }

  function renderCreateAO() {
    return (
      <Menu className={s.noFilesMenu}>
        <Menu.Item
          key="1"
          className={s.noFilesMenuItem}
          onClick={() => history.push(`${urls.getActivationOrderCreateLink()}/available_receivables/${companyId}`)}
        >
          NOW
        </Menu.Item>
        <Menu.Item
          key="2"
          className={s.noFilesMenuItem}
          onClick={() => history.push(`${urls.getActivationOrderCreateLink()}/classic/${companyId}`)}
        >
          CLASSIC
        </Menu.Item>
        <Menu.Item
          key="3"
          className={s.noFilesMenuItem}
          onClick={() => history.push(`${urls.getActivationOrderCreateLink()}/future/${companyId}`)}
        >
          FUTURE
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <div className={styles.wrapper}>
      {isAdmin && preparedAOByCompanyData.length === 0 && (
        <div className={s.noFiles}>
          <div>There are no files yet</div>
          <Dropdown overlay={renderCreateAO} trigger={['click']}>
            <Button style={{ backgroundColor: '#9197AD', color: '#fff', border: 'none' }} icon={<AddIcon size={16} />}>
              Create AO
            </Button>
          </Dropdown>
        </div>
      )}
      <div>
        {isAdmin && preparedAOByCompanyData.length > 0 && (
          <div style={{ display: 'flex', height: '52px', justifyContent: 'flex-end' }}>
            <Dropdown overlay={renderCreateAO} trigger={['click']}>
              <Button
                style={{ backgroundColor: '#9197AD', color: '#fff', border: 'none' }}
                icon={<AddIcon size={16} />}
              >
                Create AO
              </Button>
            </Dropdown>
          </div>
        )}
        <Table columns={columns} dataSource={preparedAOByCompanyData} loading={isLoading} />
      </div>
    </div>
  );
};

export default AOClient;
