import React, { Children, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { tableStyleAo } from 'theme/styles';
import endpoints from 'consts/endpoints';
import { formatDate } from 'helpers/formatters';
import TableWrapWithPagination from 'components/tables/TableWrapWithPagination';
import { getFileStorage } from 'components/forms/CompanyForm/reportsData';
import useContracts, { contractDataBaseType } from 'hooks/api/useContracts';
import Tooltip from 'components/Tooltip';

import styles from './styles.module.scss';
import { useUserContext } from 'contexts/UserProvider';

const downloadSvg = require('assets/img/download.svg').default;

type PropsT = {
  companyId?: string;
  setCurrentDocument: (data: contractDataBaseType) => void;
  setIsOpen: (data: boolean) => void;
};

const Agreements: FC<PropsT> = ({ companyId, setCurrentDocument, setIsOpen }) => {
  const { t } = useTranslation();
  const classes = tableStyleAo();
  const { getDocumentsByIdV2, contractsData } = useContracts();
  const { user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  useEffect(() => {
    if (!companyId) return;
    getDocumentsByIdV2({
      id: companyId,
      endpoint: 'getContracts',
      resultKey: 'contractsData',
    });
  }, []);

  if (!contractsData.contracts) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <TableWrapWithPagination tableContainerStyle={{ backgroundColor: 'inherit' }} isShowPagination={false}>
        <TableHead style={{ height: '40px', fontWeight: 500 }} classes={{ root: classes.documentHeaderRowRoot }}>
          <TableRow className={styles.headRow}>
            <TableCell style={{ width: '48%' }} classes={{ root: classes.documentHederRoot }}>
              {t('forms.companies.agreements.name')}
            </TableCell>
            <TableCell style={{ width: 200 }} classes={{ root: classes.documentHederRoot }}>
              {t('forms.companies.agreements.number')}
            </TableCell>
            <TableCell classes={{ root: classes.documentHederRoot }} style={{ width: 160, textAlign: 'right' }}>
              {t('forms.companies.agreements.date')}
            </TableCell>
            <TableCell classes={{ root: classes.documentHederRoot }} style={{ width: 100 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {Children.toArray(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            contractsData.contracts.map((item) => (
              <TableRow
                style={{ height: 56 }}
                className={styles.tableRowStyle}
                onClick={() => {
                  if (isAdmin) {
                    setIsOpen(true);
                    setCurrentDocument(item);
                  }
                }}
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.number}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>{formatDate(item.date)}</TableCell>
                <TableCell
                  align="right"
                  className={styles.actionTableCell}
                  style={{ paddingRight: 51, position: 'relative' }}
                >
                  <Tooltip tooltipWithoutArrow title="Download">
                    <img
                      style={{ cursor: 'pointer', width: 18 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        getFileStorage(endpoints.getStorageFileUrl(item.link));
                      }}
                      src={downloadSvg}
                      alt="download"
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            )),
          )}
        </TableBody>
      </TableWrapWithPagination>
    </div>
  );
};

export default Agreements;
