import React, { FC, useEffect, useState } from 'react';
import { Button, Collapse, Table, UploadIcon } from '@gamesb42/ui-kit';

import useActivationOrder, { activationOrderDataType, AoTypeEnum } from 'hooks/api/useActivationOrder';
import GeneralInformation from 'components/forms/ActivationOrderForm/General/common/GeneralInformation';
import Item, { TypeEnum } from 'components/common/GeneralView/Item';
import Card from 'components/common/GeneralView/Card';
import DistributionPlatformList from 'components/forms/ActivationOrderForm/General/common/DistributionPlatformList';
import { GeneralEnum } from 'components/forms/ActivationOrderForm/data';
import { AOKeyEnum, Account } from 'types/ActivationOrderTypes';

import Flex from 'components/Flex';
import { formatAmount, formatDate, formatPercent } from 'helpers/formatters';
import MainBlock from './MainBlock';
import pdfSvg from 'assets/img/pdf.svg';
import { getFileStorage } from 'components/forms/CompanyForm/reportsData';
import { amountWithCurr, formatInterest, replaceInString } from './helpers';
import endpoints from 'consts/endpoints';
import s from './styles.module.scss';
import UploadAOChangeStatus from 'components/modal/UploadAOChangeStatus';
import getActivationOrder from 'queries/activationOrder/getActivationOrder';
import { useParams } from 'react-router-dom';

type PropsT = {
  activationOrderData: activationOrderDataType;
  setStatus: any;
};

interface IDistributionPlatform {
  platform_id: string;
  platform: string;
  platform_logo: string;
  minimal_repayment_amount: string;
  minimal_repayment_percent: string;
}

interface IRepaymentSchedule {
  payment_date: string;
  amount: string;
}

const FutureAO: FC<PropsT> = ({ activationOrderData, setStatus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // @ts-ignore
  const { activationOrderId } = useParams();
  const [link, setLink] = useState(activationOrderData?.ao_document?.link);

  const revenueColumns = [
    {
      title: 'Distribution Platform',
      width: '312px',
      align: 'left' as 'left',
      render: (row: IDistributionPlatform) => (
        <Flex align="center" gap="4px">
          <img src={row.platform_logo} />
          <div>{row?.platform}</div>
        </Flex>
      ),
    },
    {
      title: 'MR %',
      width: '312px',
      align: 'right' as 'right',
      render: (row: IDistributionPlatform) => <div>{formatPercent(Number(row?.minimal_repayment_percent))}%</div>,
    },
    {
      title: 'MRA',
      align: 'right' as 'right',
      render: (row: IDistributionPlatform) => {
        return <div>{amountWithCurr(activationOrderData.currency, row?.minimal_repayment_amount as string)}</div>;
      },
    },
  ];

  const repaymentColumns = [
    {
      title: 'Payment date',
      width: '312px',
      align: 'left' as 'left',
      render: (row: IRepaymentSchedule) => <div>{formatDate(row.payment_date)}</div>,
    },
    {
      title: 'Amount',
      align: 'right' as 'right',
      render: (row: IRepaymentSchedule) => {
        return <div>{amountWithCurr(activationOrderData.currency, row?.amount as string)}</div>;
      },
    },
  ];

  const download = async (link: string) => {
    setIsLoading(true);
    await getFileStorage(endpoints.getStorageFileUrl(link));
    setIsLoading(false);
  };

  return (
    <Flex direction="column" gap="32px">
      <MainBlock activationOrderData={activationOrderData} />

      <Flex direction="row" justify="space-between" style={{ padding: '8px 12px', height: '56px', background: '#fff' }}>
        <div
          style={{ cursor: 'pointer', display: 'flex', gap: '8px', alignItems: 'center' }}
          onClick={() => {
            if (isLoading || !link) return;
            download(link);
          }}
        >
          <img src={pdfSvg} alt="pdfSvg" />
          <div style={{ width: '700px', color: '#7145EC', textDecoration: 'underline' }}>
            {activationOrderData.ao_document?.file_name}
          </div>
        </div>

        <Flex justify="flex-end" align="center" gap="20px">
          <Button
            type="iconBtn"
            icon={<UploadIcon size={24} />}
            onClick={() => {
              if (isLoading || !link) return;
              setIsOpen(true);
            }}
          />
        </Flex>
        <UploadAOChangeStatus
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          setStatus={setStatus}
          activationOrderId={activationOrderId}
          setLink={setLink}
        />
      </Flex>

      <Collapse collapsible="header" defaultActiveKey={['1']}>
        <Collapse.Panel header="Revenue Sources" key="1">
          <Table
            style={{ maxWidth: '890px' }}
            columns={revenueColumns}
            dataSource={activationOrderData?.distribution_platforms}
          />
        </Collapse.Panel>
        <Collapse.Panel header="Repayment schedule" key="2">
          <Table
            style={{ maxWidth: '890px' }}
            columns={repaymentColumns}
            dataSource={activationOrderData?.payment_schedules}
          />
        </Collapse.Panel>
        <Collapse.Panel header="Custom Settings" key="3">
          {/* @ts-ignore */}
          {activationOrderData?.di_period_disabled?.map((period) => (
            <Flex align="center" className={s.settings} gap="24px">
              <div className={s.settingsLabel}>Turn off DI for period:</div>
              <div className={s.settingsValue}>
                {formatDate(period.date_start)}-{formatDate(period.date_end)}
              </div>
            </Flex>
          ))}
        </Collapse.Panel>
      </Collapse>
    </Flex>
  );
};

export default FutureAO;
