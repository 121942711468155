import React, { FC, useEffect, useState, ReactNode } from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/uiKit/Button';
import PopUpLayout from 'helpers/PopUpLayout';
import TemplateGrid from 'components/forms/ActivationOrderForm/CardsAo/TemplateGrid';
import { CurrencyType } from 'components/uiKit/InputKit';
import DateInput from 'components/inputs/DateInput';
import { AmountKit } from 'components/uiKit/AmountKit';
import { formatAmount, getCurrency, getNameCurrencyOfCode, getYYYYMMDD } from 'helpers/formatters';
import useErrors from 'components/forms/ActivationOrderForm/CardsAo/useErrors';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';

import styles from './styles.module.scss';

const wrapStyle = {
  width: 720,
  backgroundColor: '#F7F8FA',
  borderRadius: 16,
  padding: '44px 40px 38px 40px',
  boxSizing: 'border-box' as const,
};

interface InitialDataType {
  date: string;
  amount: string;
}

interface TitleWithDateType {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
  leftCallback: (data: InitialDataType) => void;
  rightCallback?: () => void;
  firstCellContent: ReactNode;
  firstCellTitle?: string;
  secondCellContent: ReactNode;
  secondCellTitle?: string;
  thirdCellContent: ReactNode;
  thirdCellTitle?: string;
  currency: CurrencyType;
  secondLogo?: string;
  disabled?: boolean;
  total?: number;
}

const CreateTransactionCompanyPopUp: FC<TitleWithDateType> = ({
  isOpen,
  onClose,
  leftCallback,
  rightCallback,
  disabled,
  firstCellContent,
  firstCellTitle = i18next.t('forms.activationOrder.request'),
  secondCellContent,
  secondCellTitle = i18next.t('forms.activationOrder.revenueSource'),
  thirdCellContent,
  thirdCellTitle = i18next.t('forms.activationOrder.outstanding'),
  companyId,
  secondLogo,
  currency,
  total,
}) => {
  const { t } = useTranslation();
  const [initial, setInitial] = useState({
    date: '',
    amount: '',
  });
  const { isErrors, errors, addErrors, clearErrorsOfKey } = useErrors();
  const { getAccountsByIdCompany, accountsByIdCompany }: useCompanyType = useCompany();

  const availableBalance =
    accountsByIdCompany?.find((item) => {
      return (
        getNameCurrencyOfCode(item.currency) === currency.toUpperCase() &&
        item.type === 'counterparty-client' &&
        item.company_id.replaceAll('-', '') === companyId.replaceAll('-', '')
      );
    })?.balance || 0.0;

  const availableBalanceContent = `${availableBalance < 0 ? '-' : ''}${getCurrency(currency)}${formatAmount(
    Math.abs(availableBalance),
  )}`;

  useEffect(() => {
    if (typeof total === 'number' && Number(initial?.amount) > 0 && Number(initial?.amount) > total) {
      addErrors({ total: 'Repayment amount exceeds an outstanding one' });

      return;
    }
    clearErrorsOfKey('total');
  }, [initial.amount, thirdCellContent, availableBalance, total]);

  useEffect(() => {
    if (Number(initial?.amount) > availableBalance) {
      addErrors({ amount: 'Amount is exceeding Available Balance' });

      return;
    }
    clearErrorsOfKey('amount');
  }, [initial.amount, availableBalance]);

  useEffect(() => {
    if (companyId && initial.date) {
      const date = new Date(initial.date);
      // NOTE: чтобы расчеты делались по 23:59:59 от date, нужно передать апишке следующую за этим днем дату
      date.setDate(date.getDate() + 1);
      if (isNaN(date.getTime()) === false) {
        const balanceDate = getYYYYMMDD(date);

        getAccountsByIdCompany({ company_id: companyId, balanceDate });
      }
    }
  }, [companyId, initial.date]);

  useEffect(() => {
    if (initial.date.length > 0) clearErrorsOfKey('date');
  }, [initial.date]);

  useEffect(() => {
    return () => {
      clearErrorsOfKey('total');
      clearErrorsOfKey('amount');
    };
  }, []);

  return (
    <PopUpLayout style={wrapStyle} open={isOpen} onClose={onClose}>
      <div className={styles.title}>{t('forms.activationOrder.createTransaction')}</div>
      <div className={styles.wrapper}>
        <div className={classNames(styles.table, styles['table--header'])}>
          <div>
            <span>{firstCellTitle}</span>
          </div>
          <div>
            <span>{secondCellTitle}</span>
          </div>
          <div>
            <span className={classNames({ [styles.error]: !!errors.total })}>{thirdCellTitle}</span>
          </div>
          <div className={classNames(styles.availableBalance, { [styles.error]: !!errors.amount })}>
            <span>{t('forms.activationOrder.availableBalance')}</span>
          </div>
        </div>
        <div className={classNames(styles.table, styles['table--body'])}>
          <div>
            <span>{firstCellContent}</span>
          </div>
          <div className={styles.row}>
            {secondLogo && <img src={secondLogo} alt="ico" className={styles.logo} />}
            <span>{secondCellContent}</span>
          </div>
          <div className={classNames({ [styles.error]: !!errors.total })}>{thirdCellContent}</div>
          <div className={classNames({ [styles.error]: !!errors.amount })}>
            <span>{availableBalanceContent}</span>
          </div>
        </div>
      </div>
      <TemplateGrid style={{ height: 60, gridTemplateColumns: 'auto auto' }}>
        <DateInput
          labelKey={t('forms.activationOrder.date')}
          date={initial.date}
          required
          onChangeDateHandler={({ target: { value } }) => setInitial({ ...initial, date: value })}
          disabled={disabled}
          isError={!!errors.date}
          errorText={errors.date}
        />
        <AmountKit
          label={t('tables.amount')}
          amount={initial.amount}
          wrapProps={{ height: 60 }}
          currency={currency}
          onChange={(e) => {
            setInitial({ ...initial, amount: e.target.value });
          }}
          disabled={disabled}
          required
          isError={!!errors.amount || !!errors.total}
          helperText={errors.amount || errors.total}
          helperTextStyle={{ bottom: -17 }}
        />
      </TemplateGrid>
      <div style={{ display: 'flex', marginTop: 32 }}>
        <Button
          variant="fill"
          color="grey_light"
          onClick={() => {
            if (!initial.date || !initial.amount) {
              !initial.date && addErrors({ date: 'The date is not filled' });
              !initial.amount && addErrors({ amount: 'The amount is not filled' });
              return;
            }

            leftCallback(initial);
          }}
          className={styles.createTransaction}
          disabled={disabled || isErrors}
        >
          {t('forms.activationOrder.createTransaction')}
        </Button>
        <Button disabled={disabled} onClick={rightCallback} className={styles.cancel}>
          {t('forms.activationOrder.cancel')}
        </Button>
      </div>
    </PopUpLayout>
  );
};

export default CreateTransactionCompanyPopUp;
