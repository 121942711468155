// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import lodash from 'lodash';

import Button from 'components/uiKit/Button';
import useAlert from 'hooks/api/useAlert';
import usePurchaseRequest, { PurchaseRequestEnum, PurchaseRequestStatus } from 'hooks/api/usePurchaseRequest';
import useErrorCallback from 'hooks/useErrorCallback';
import { PurchaseRequestSetStatusT } from 'queries/purchaseRequest/setNewStatus';
import getParamsFromQuery from 'helpers/getParamsFromQuery';
import Title from 'components/common/Title';
import urls from 'constants/urls';
import Status from 'components/common/Status';
import { useUserContext } from 'contexts/UserProvider';

import General from './General';
import ActionsButton from './ActionButtons';
import PurchaseStatusModal from './Modal';
import styles from './styles.module.scss';

const statusForOpenModal: PurchaseRequestStatus[] = [
  PurchaseRequestStatus.REPAID,
  PurchaseRequestStatus.SIGNED,
  PurchaseRequestStatus.REJECTED,
  PurchaseRequestStatus.FUNDED,
];

const PurchaseRequest = () => {
  const { t } = useTranslation();
  const { getPurchaseRequest, purchaseRequestData, clearPurchaseRequestData, setNewStatus, deletePurchaseRequest } =
    usePurchaseRequest();
  const { purchaseRequestId } = useParams();
  const { setAlert } = useAlert();
  const history = useHistory();
  const { type } = getParamsFromQuery<{ type: PurchaseRequestEnum }>(useLocation().search, ['type']);
  const [isLoading, setIsLoading] = useState(false);
  const { errorCallback } = useErrorCallback(() => {
    setIsLoading(false);
  });
  const currentStatus = purchaseRequestData?.status;
  const [modalProps, setModalProps] = useState<{ type: PurchaseRequestStatus; isOpen: boolean }>({
    type: currentStatus,
    isOpen: false,
  });
  const { user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  const title = 'Request details';

  const successCallback = useCallback(async () => {
    await getPurchaseRequest({ id: purchaseRequestId });
    setAlert({
      type: 'success',
      message: `${t('forms.purchaseRequest.successChangedStatus')} `,
    });
    setIsLoading(false);
  }, [t, purchaseRequestId]);

  const setStatus = async (params: PurchaseRequestSetStatusT['params']) => {
    await setNewStatus({ params, successCallback, errorCallback });
  };

  const changeStatus = async (newStatus) => {
    setIsLoading(true);

    if (statusForOpenModal.includes(newStatus) && currentStatus !== newStatus) {
      setModalProps({ isOpen: true, type: newStatus });

      return;
    }
    await setStatus({ id: purchaseRequestId, status: newStatus });
  };

  const onDeletePurchaseRequest = () => {
    deletePurchaseRequest({
      id: purchaseRequestId,
      successCallback: () => {
        history.replace(urls.getPurchaseRequestList());
      },
    });
  };

  useEffect(() => {
    if (purchaseRequestId) {
      getPurchaseRequest({ id: purchaseRequestId });
    }

    return () => {
      clearPurchaseRequestData();
    };
  }, []);

  return (
    <>
      <div className={styles.headerWrapper}>
        {!lodash.isEmpty(purchaseRequestData) && (
          <>
            <div className={styles.titleWrapper}>
              <Title
                clickCallback={() => {
                  history.push(urls.getPurchaseRequestList());
                }}
                text={title}
                isShowBackButton
              />
              <Status status={currentStatus.toUpperCase()} className={styles.status} />
            </div>
            <div className={styles.actionWrapper}>
              {isAdmin && (
                <ActionsButton
                  id={purchaseRequestData.id}
                  title={purchaseRequestData.name}
                  status={currentStatus}
                  changeStatusToNew={() => changeStatus(PurchaseRequestStatus.NEW)}
                  onDeletePurchaseRequest={onDeletePurchaseRequest}
                />
              )}
            </div>
          </>
        )}
      </div>
      {!lodash.isEmpty(purchaseRequestData) && (
        <General type={type} title={title} purchaseRequestData={purchaseRequestData} />
      )}
      {isAdmin && (
        <div className={styles.footer}>
          {[PurchaseRequestStatus.SIGNED, PurchaseRequestStatus.PENDING, PurchaseRequestStatus.FUNDED].includes(
            currentStatus,
          ) && (
            <Button
              variant="fill"
              color="red"
              gutters={[8, 20]}
              loading={isLoading}
              onClick={() => changeStatus(PurchaseRequestStatus.REJECTED)}
            >
              {t('forms.purchaseRequest.reject')}
            </Button>
          )}
          {currentStatus === PurchaseRequestStatus.NEW && (
            <Button
              variant="fill"
              color="grey_light"
              gutters={[8, 20]}
              loading={isLoading}
              onClick={() => changeStatus(PurchaseRequestStatus.PENDING)}
            >
              {t('forms.purchaseRequest.proceed')}
            </Button>
          )}
          {currentStatus === PurchaseRequestStatus.SIGNED && (
            <Button
              variant="fill"
              color="grey_light"
              gutters={[8, 20]}
              loading={isLoading}
              onClick={() => changeStatus(PurchaseRequestStatus.FUNDED)}
            >
              {t('forms.purchaseRequest.fund')}
            </Button>
          )}
          {currentStatus === PurchaseRequestStatus.PENDING && (
            <Button
              variant="fill"
              color="grey_light"
              gutters={[8, 20]}
              loading={isLoading}
              onClick={() => changeStatus(PurchaseRequestStatus.SIGNED)}
            >
              {t('forms.purchaseRequest.changeToSigned')}
            </Button>
          )}
        </div>
      )}
      {modalProps.type && (
        <PurchaseStatusModal
          setStatus={setStatus}
          purchaseRequestData={purchaseRequestData}
          {...modalProps}
          onClose={() => {
            setModalProps((prev) => ({ ...prev, isOpen: false }));
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
};

export default PurchaseRequest;
