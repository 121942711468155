import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, DownloadIcon, Modal, Radio, Table } from '@gamesb42/ui-kit';

import { getToken } from 'helpers/token';
import documentSvg from './assets/doc.svg';
import s from './styles.module.scss';
import { downloadFileOfBlob } from 'helpers/file';
import { changeStatusCompany, createCompanyAgreement } from 'api/company';
import { CompanyStatus } from 'types/CompanyTypes';
import { useUserContext } from 'contexts/UserProvider';

export function AMLKYC({ companyData, setCompanyData }: any) {
  //@ts-ignore
  const { companyId } = useParams();
  const [filesData, setFilesData] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingZip, setLoadingZip] = useState(false);
  const [status, setStatus] = useState(
    companyData.status === 'rejected' ? 'rejected' : companyData.status === 'verified' ? 'verified' : '',
  );
  const { user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';
  const isAML = user?.role === 'factor-aml';

  const columns = [
    {
      title: 'Document type',
      dataIndex: 'displayed_doc_type',
    },
    {
      title: 'File',
      render: (_: any, record: any) => {
        const isFileName = record.file?.name;

        function renderColumn() {
          if (isFileName) {
            return (
              <div className={s.fileRow}>
                {isFileName && <img src={documentSvg} alt="icon" />}
                <div
                  onClick={() => {
                    if (isFileName) handleDownloadClick(record.file.id, isFileName);
                  }}
                >
                  {isFileName}
                </div>
              </div>
            );
          } else {
            return <div className={s.fileRowNoData}>-</div>;
          }
        }

        return renderColumn();
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      render: (_: any, record: any) => {
        if (record?.comment?.length > 0) {
          return <div>{record?.comment}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
  ];

  const handleDownloadClick = async (fileId: string, fileNameIn: string) => {
    let myHeaders = new Headers();
    myHeaders.append('accept', '*/*');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    const blob = await fetch(`${process.env.REACT_APP_SCA_URL}/v1/documents/file/${fileId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => response.blob());

    downloadFileOfBlob(blob, 'application/pdf', fileNameIn, true);
  };

  const handleDownloadAllClick = async () => {
    setLoadingZip(true);
    let fileName = 'files.zip';

    let myHeaders = new Headers();
    myHeaders.append('accept', '*/*');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    const blob = await fetch(`${process.env.REACT_APP_SCA_URL}/v1/documents/company/${companyId}/zip`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => {
        const xFileName = response.headers.get('x-file-name');

        if (xFileName) {
          fileName = xFileName;
        }

        return response;
      })
      .then((response) => response.blob());
    downloadFileOfBlob(blob, 'typeLocal', fileName, false);
    setLoadingZip(false);
  };

  useEffect(() => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    fetch(`${process.env.REACT_APP_SCA_URL}/v1/documents/company/${companyId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => setFilesData(result))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={s.wrapper}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ width: '100%' }}>
            {(isAdmin || isAML) && (
              <Radio.Group value={status} disabled={companyData.status !== 'in-review'}>
                <Radio
                  onChange={() => {
                    Modal.warning({
                      title: (
                        <>
                          Confirm the status «<span style={{ color: '#0E9C1C' }}>Verified</span>»?
                        </>
                      ),
                      okText: 'Confirm',
                      okButtonProps: {
                        className: s.del,
                      },
                      onOk: () => {
                        setStatus('verified');
                        return createCompanyAgreement(companyId)
                          .then((data) => setCompanyData(data))
                          .catch(() => {});
                      },
                    });
                  }}
                  value={'verified'}
                >
                  <span style={{ color: '#26B634' }}>Verified</span>
                </Radio>
                <Radio
                  onChange={() => {
                    Modal.warning({
                      title: (
                        <>
                          Confirm the status «<span style={{ color: '#EC3E72' }}>Rejected</span>»?
                        </>
                      ),
                      okText: 'Confirm',
                      okButtonProps: {
                        className: s.del,
                      },
                      onOk: () => {
                        setStatus('rejected');
                        return changeStatusCompany(companyId, 'rejected' as CompanyStatus)
                          .then((data) => setCompanyData(data))
                          .catch(() => {});
                      },
                    });
                  }}
                  value={'rejected'}
                >
                  <span style={{ color: '#EC3E72' }}>Rejected</span>
                </Radio>
              </Radio.Group>
            )}
          </div>
          <div
            style={{
              width: !isAdmin ? '100%' : '',
              display: !isAdmin ? 'flex' : '',
              justifyContent: !isAdmin ? 'flex-end' : '',
            }}
          >
            <Button
              style={{ cursor: 'pointer' }}
              onClick={handleDownloadAllClick}
              disabled={loadingZip}
              icon={<DownloadIcon size={24} />}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <Table
        className={s.table}
        columns={columns}
        dataSource={filesData}
        loading={loading}
        scroll={{ y: 'max-content' }}
      />
    </div>
  );
}
