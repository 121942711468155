/* eslint-disable max-lines */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import preval from 'preval.macro';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  GBIcon,
  Button,
  ResetIcon,
  notification,
  Tooltip,
  TransactionIcon,
  AoIcon,
  RequestIcon,
  CalendarIcon,
  CompanyIcon,
  ReportIcon,
  UserIcon,
  ConnectionIcon,
  SettingIcon,
  ArrowTrMiniRightIcon,
  ArrowLeftIcon,
  MenuHighIcon,
  Avatar,
  LogOutIcon,
  WorkingCapitalIcon,
} from '@gamesb42/ui-kit';

import urls from 'constants/urls';
import styles from './styles.module.scss';
import { getBackendVersion } from 'api/backendVersion';
import classNames from 'classnames';
import { BackendVersion, MENU_ITEM_TYPE, MenuItemLink, MenuItemDivider, MenuItemSubMenu } from 'types/Menu';
import { Location } from 'history';
import { useUserContext } from 'contexts/UserProvider';
import { UserKeyEnum } from 'types/UserTypes';

const CURRENT_VERSION = window.ENV?.REACT_APP_VERSION || process.env.REACT_APP_VERSION;
const APP_BUILD_TIME = preval`module.exports = new Date()`;
const APP_BUILD_BRANCH = window.ENV?.REACT_APP_BUILD_BRANCH || process.env.REACT_APP_BUILD_BRANCH;
const APP_BUILD_REVISION = window.ENV?.REACT_APP_BUILD_REVISION || process.env.REACT_APP_BUILD_REVISION;

const getReplacedTime = (str: Date) => {
  try {
    const dateValue: Date = new Date(str);
    const dateStr = dateValue.toISOString().replace(/T/, ' ').replace(/\..+/, '');

    return `${dateStr} UTC`;
  } catch (e) {
    return str;
  }
};

const getTooltipText = (backendVersion: BackendVersion | null) => {
  const frontBuildTime = getReplacedTime(APP_BUILD_TIME);

  if (!backendVersion) {
    return (
      <span>
        {`Build Time: ${frontBuildTime}.`}
        <br />
        Error loading backend version
      </span>
    );
  }
  const frontBranchMsg = APP_BUILD_BRANCH ? `Frontend: Built from branch '${APP_BUILD_BRANCH}'` : '';
  const frontRevisionMsg = APP_BUILD_REVISION ? `Frontend: Revision '${APP_BUILD_REVISION}'` : '';
  const backBranchMsg = backendVersion.build_branch
    ? `Backend: Built from branch '${backendVersion.build_branch}'`
    : '';
  const backRevisionMsg = backendVersion.build_revision ? `Backend: Revision '${backendVersion.build_revision}'` : '';
  const backBuildTime = getReplacedTime(backendVersion.build_date);
  const frontBuildTimeMsg = `Front Build Time: ${frontBuildTime}`;
  const backBuildTimeMsg = `Back Build Time: ${backBuildTime}`;
  const errorMessage =
    CURRENT_VERSION !== backendVersion.version
      ? `The app version ${CURRENT_VERSION} is different from the server version (${backendVersion.version})`
      : '';

  return (
    <span className={styles.tooltip}>
      {[
        frontBuildTimeMsg,
        frontBranchMsg,
        frontRevisionMsg,
        backBuildTimeMsg,
        backBranchMsg,
        backRevisionMsg,
        errorMessage,
      ]
        .filter(Boolean)
        .join('\n')}
    </span>
  );
};

const Menu = () => {
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useHistory();
  const [backendVersion, setBackendVersion] = useState<BackendVersion | null>(null);
  const [currentSubMenu, setCurrentSubMenu] = useState<null | string>(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);
  const { logOut, user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  const updateBackendVersion = () =>
    getBackendVersion()
      .then(setBackendVersion)
      .catch(() => notification.error({ message: 'Error loading backend version' }));

  const menuItems: Array<MenuItemLink | MenuItemDivider | MenuItemSubMenu | null> = useMemo(
    () => [
      {
        type: MENU_ITEM_TYPE.LINK,
        title: t('menu.transactions'),
        Icon: TransactionIcon,
        to: (location: Location) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const path = location.state?.prevPath ? location.state.prevPath : urls.getTransactions();
          return path;
        },
      },
      {
        type: MENU_ITEM_TYPE.LINK,
        to: urls.getActivationOrder(),
        title: t('menu.activationOrder'),
        Icon: AoIcon,
      },
      {
        type: MENU_ITEM_TYPE.LINK,
        to: urls.getPurchaseRequestList(),
        title: t('menu.purchase-request'),
        Icon: RequestIcon,
      },
      {
        type: MENU_ITEM_TYPE.DIVIDER,
      },
      {
        type: MENU_ITEM_TYPE.LINK,
        to: urls.getExpectedPayments(),
        title: t('menu.expectedPayments'),
        Icon: () => <CalendarIcon size={24} />,
      },
      {
        type: MENU_ITEM_TYPE.SUB_MENU,
        title: t('menu.reports'),
        Icon: ReportIcon,
        subItems: [
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getCommissionReport(),
            title: t('menu.commissionReport'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getExpectedPayments(),
            title: t('menu.expectedPayments'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getPivotTransactions(),
            title: t('menu.pivotTransactions'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getClientAmountsReport(),
            title: t('menu.clientAmounts'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getAccrualReport(),
            title: t('menu.accrualReport'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getReconciliationReport(),
            title: t('menu.reconciliationReport'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getSellerAccountBalance(),
            title: t('menu.sellerAccountBalance'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getDashboard(),
            title: t('menu.dashboard'),
          },
          {
            type: MENU_ITEM_TYPE.SUB_LINK,
            to: urls.getRepaymentSchedule(),
            title: 'Repayment Schedule',
          },
        ],
      },
      {
        type: MENU_ITEM_TYPE.DIVIDER,
      },
      {
        type: MENU_ITEM_TYPE.LINK,
        to: urls.getCompany(),
        title: t('menu.company'),
        Icon: CompanyIcon,
      },
      isAdmin
        ? {
            type: MENU_ITEM_TYPE.LINK,
            to: urls.getUsers(),
            title: t('menu.user'),
            Icon: UserIcon,
          }
        : null,
      isAdmin
        ? {
            type: MENU_ITEM_TYPE.LINK,
            to: urls.getAdmins(),
            title: 'Admins',
            Icon: UserIcon,
          }
        : null,
      {
        type: MENU_ITEM_TYPE.DIVIDER,
      },
      isAdmin
        ? {
            type: MENU_ITEM_TYPE.LINK,
            to: urls.getRevenueSourceList(),
            title: t('menu.revenueSources'),
            Icon: ConnectionIcon,
          }
        : null,
      {
        type: MENU_ITEM_TYPE.LINK,
        to: urls.getWorkingCapital() + '?order=desc&sortedBy=receiptDate',
        title: t('menu.workingCapital'),
        Icon: WorkingCapitalIcon,
      },
      isAdmin
        ? {
            type: MENU_ITEM_TYPE.SUB_MENU,
            title: t('menu.settings'),
            Icon: SettingIcon,
            subItems: [
              {
                type: MENU_ITEM_TYPE.SUB_LINK,
                to: urls.getSettingsGeneral(),
                title: t('menu.general'),
              },
              {
                type: MENU_ITEM_TYPE.SUB_LINK,
                to: urls.getSettingsRecalculate(),
                title: t('menu.recalculate'),
              },
              {
                type: MENU_ITEM_TYPE.SUB_LINK,
                to: urls.getSettingsFactorDetails(),
                title: t('menu.factorDetails'),
              },
              {
                type: MENU_ITEM_TYPE.SUB_LINK,
                to: urls.getSettingsKeycloak(),
                title: t('menu.keycloak'),
              },
              {
                type: MENU_ITEM_TYPE.SUB_LINK,
                to: urls.getSettingsGoogleApi(),
                title: t('menu.googleApi'),
              },
              {
                type: MENU_ITEM_TYPE.SUB_LINK,
                to: urls.getSettingsSubAccounts(),
                title: 'Sub accounts',
              },
            ],
          }
        : null,
    ],
    [t, isAdmin],
  );

  useEffect(() => {
    updateBackendVersion();

    const subMenuItems = menuItems?.filter((menu) => menu?.type === MENU_ITEM_TYPE.SUB_MENU);
    (subMenuItems as MenuItemSubMenu[]).forEach(({ subItems, title }) => {
      subItems.forEach(({ to }) => {
        if (to === pathname) {
          setOpenSubMenu(true);
          setCurrentSubMenu(title);
        }
      });
    });
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.menu}>
        <div className={styles.header}>
          <Link to="/" className={styles.home}>
            <GBIcon />
          </Link>
          <div className={styles.version}>
            <Tooltip title={getTooltipText(backendVersion)}>
              <span
                className={classNames(
                  styles.versionText,
                  CURRENT_VERSION !== backendVersion?.version && styles.versionTextError,
                )}
              >
                v.{CURRENT_VERSION}
              </span>
            </Tooltip>
            <Button type="iconBtn" icon={<ResetIcon size={16} />} onClick={updateBackendVersion}></Button>
          </div>
        </div>
        <div className={styles.body}>
          {menuItems.map((menu, index) => {
            switch (menu?.type) {
              case MENU_ITEM_TYPE.LINK: {
                const { Icon } = menu;
                return (
                  <NavLink key={menu.title} className={styles.link} activeClassName={styles.activeLink} to={menu.to}>
                    <Icon />
                    <span className={styles.name}>{menu.title}</span>
                  </NavLink>
                );
              }
              case MENU_ITEM_TYPE.SUB_MENU: {
                const { Icon } = menu;
                return (
                  <button
                    className={styles.button}
                    key={menu.title}
                    type="button"
                    onClick={() => {
                      setCurrentSubMenu(menu.title);
                      setOpenSubMenu(true);
                    }}
                  >
                    <span className={styles.buttonContainer}>
                      <Icon />
                      <span className={styles.name}>{menu.title}</span>
                    </span>
                    <ArrowTrMiniRightIcon />
                  </button>
                );
              }
              case MENU_ITEM_TYPE.DIVIDER:
                return <div className={styles.divider} key={index} />;

              default:
                return null;
            }
          })}
          <div className={classNames(styles.subMenu, openSubMenu && styles.subMenuOpen)}>
            <button className={styles.button} type="button" onClick={() => setOpenSubMenu(false)}>
              <span className={styles.buttonContainer}>
                <ArrowLeftIcon size={24} />
                <span className={styles.name}>Back to menu</span>
              </span>
            </button>
            <div className={styles.divider} />
            {(
              menuItems.find((menu) => menu?.type === MENU_ITEM_TYPE.SUB_MENU && menu?.title === currentSubMenu) as
                | MenuItemSubMenu
                | undefined
            )?.subItems.map((subItem) => (
              <NavLink
                key={subItem.title}
                className={classNames(styles.link, styles.subLink)}
                activeClassName={styles.activeSubLink}
                to={subItem.to}
              >
                <MenuHighIcon className={styles.subMenuIcon} />
                <span>{subItem.title}</span>
              </NavLink>
            ))}
          </div>
        </div>
      </div>

      <div className={classNames(styles.footer, showLogOut && styles.footerShowLogOut)}>
        {user && (
          <button className={styles.avatar} onClick={() => setShowLogOut((oldValue) => !oldValue)}>
            <Avatar name={user[UserKeyEnum.FIRST_NAME]} surname={user[UserKeyEnum.LAST_NAME]} />
          </button>
        )}
        <button className={styles.button} type="button" onClick={logOut}>
          <span className={styles.buttonContainer}>
            <LogOutIcon />
            <span className={styles.name}>Log out</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Menu;
