import baseRequest from 'queries/baseRequest';
import endpoints from 'consts/endpoints';
import { User, UserRole } from 'types/UserTypes';

export const getMe = () => baseRequest({ path: endpoints.getMeUrl() });
export const getUser = (userId: string) => baseRequest<User>({ path: endpoints.getUserScaUrl(userId) });
export const createUser = (body: Partial<User>) =>
  baseRequest({
    path: endpoints.getUsersScaUrl(),
    options: {
      method: 'POST',
      body,
    },
  });

export const changeRole = (userId: string, role: UserRole) =>
  baseRequest({
    path: endpoints.getUserScaUrl(userId),
    options: {
      method: 'PATCH',
      body: {
        role,
      },
    },
  });
export const editUser = (body: Partial<User>, userId: string) =>
  baseRequest({
    path: endpoints.getUserScaUrl(userId),
    options: {
      method: 'PUT',
      body,
    },
  });
export const getUserCompany = (userId: string) => baseRequest({ path: endpoints.getUserCompanyScaUrl(userId) });
export const deleteUser = (userId: string) =>
  baseRequest({ path: endpoints.getUserScaUrl(userId), options: { method: 'DELETE' } });

export const getUsers = (params?: { page: number; size: number }) =>
  baseRequest({ path: endpoints.getUsersScaUrl(params) });
export const getUsersArchive = (params?: { page: number; size: number }) =>
  baseRequest({ path: endpoints.getUsersArchiveScaUrl(params) });

export const getUsersWithRoles = (params: string) => baseRequest({ path: endpoints.getUsersScaUrlString(params) });
export const getUsersArchiveWithRoles = (params: string) =>
  baseRequest({ path: endpoints.getUsersArchiveScaUrlString(params) });

export const setActiveCompany = (userId: string, companyId: string) =>
  baseRequest({
    path: endpoints.getSetActiveCompanyForUser(userId, companyId),
    options: {
      method: 'PATCH',
    },
  });
export const addNewCompany = (userId: string, companyId: string) =>
  baseRequest({ path: endpoints.getAddCompanyForUser(userId, companyId), options: { method: 'POST' } });
export const removeCompany = (userId: string, companyId: string) =>
  baseRequest({ path: endpoints.getRemoveCompanyForUser(userId, companyId), options: { method: 'DELETE' } });
