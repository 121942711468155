//@ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import {
  AddIcon,
  Avatar,
  AvatarSkeleton,
  Button,
  Input,
  PageHeader,
  Pagination,
  Table,
  SearchIcon,
} from '@gamesb42/ui-kit';

import MainContent from 'components/MainContent';
import urls from 'constants/urls';
import { getUsers, getUsersArchive, getUsersArchiveWithRoles, getUsersWithRoles } from 'api/users';
import { UserKeyEnum, User } from 'types/UserTypes';

import styles from './styles.module.scss';
import Flex from 'components/Flex';
import ActionButton from './ActionButton';

export interface QueryParams {
  size?: number;
  page?: number;
  isArchive?: string;
  name?: string;
  roles: any;
}

interface UsersData {
  total: number;
  items: User[];
}

const Admins = ({
  history: {
    push,
    replace,
    location: { search, pathname },
  },
}: RouteComponentProps) => {
  const { t } = useTranslation();
  const [usersData, setUsersData] = useState<UsersData>({
    items: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const query = queryString.parse(search, { parseNumbers: true });
  const { page = 1, size = 30, isArchive = 'false', roles = [] } = query;
  const [isChecked, setIsChecked] = useState(isArchive === 'true');
  const [filter, setFilter] = useState('');

  // console.log('-->', filter);

  const onChangeQuery = useCallback(
    (newValues: Partial<QueryParams>) => {
      let queryParams = {
        ...newValues,
        isArchive: isChecked ? true : false,
      };

      if (filter.length > 0) queryParams = { ...queryParams, name: filter };

      replace(
        `${pathname}?${queryString.stringify(queryParams, {
          skipEmptyString: true,
        })}&roles=factor-admin&roles=factor-viewer&roles=factor-aml`,
      );
    },
    [pathname, replace, isChecked, name],
  );

  const columns = [
    {
      title: 'Admin name or email',
      dataIndex: UserKeyEnum.FIRST_NAME,
      skeletonRender: <AvatarSkeleton />,
      render: (firstName: string, row: User) => (
        <Avatar name={firstName} surname={row[UserKeyEnum.LAST_NAME]} email={row[UserKeyEnum.EMAIL]} />
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
    },
  ];

  useEffect(() => {
    let params = {
      page,
      size,
      isArchive,
    };

    //@ts-ignore
    if (filter.length > 0) params = { ...params, name: filter };

    const searchParams = new URLSearchParams(Object.entries(params));
    const queryString = searchParams.toString() + `&roles=factor-admin&roles=factor-viewer&roles=factor-aml`;

    setLoading(true);
    isArchive === 'true'
      ? getUsersArchiveWithRoles(queryString)
          .then((res) => setUsersData({ items: res.items, total: res.total }))
          .catch(console.error)
          .finally(() => setLoading(false))
      : getUsersWithRoles(queryString)
          .then((res) => setUsersData({ items: res.items, total: res.total }))
          .catch(console.error)
          .finally(() => setLoading(false));
  }, [page, size, isArchive, filter]);

  return (
    <MainContent title={'Admins'} type="flexibleLimit" flexColumn>
      <PageHeader
        title={'Admins'}
        rightContent={
          <span className={styles.rightContent}>
            <Button type="primary" icon={<AddIcon size={16} />} onClick={() => push(urls.getCreateAdmin())}>
              Invite admin
            </Button>
          </span>
        }
      />

      <Flex gap="20px" style={{ marginBottom: '12px' }}>
        <div style={{ flexGrow: 1 }}>
          <Input
            label="Admin name or email"
            suffix={<SearchIcon />}
            style={{ color: '#686F88' }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <ActionButton isChecked={isChecked} setIsChecked={setIsChecked} />
      </Flex>

      <Table
        columns={columns}
        loading={loading}
        loadingRowCount={30}
        dataSource={usersData.items}
        className={styles.table}
        rowKey={UserKeyEnum.ID}
        rowClassName={(row) => classNames(styles.row, row.status === 'deleted' && styles.deleted)}
        onRow={(record) => {
          return {
            onClick: () => push(`${urls.getAdmins()}/${record[UserKeyEnum.ID]}`),
          };
        }}
      />
      <Pagination
        total={usersData.total}
        current={page}
        pageSize={size}
        onChange={(newCurrent: number, newPageSize: number) => onChangeQuery({ page: newCurrent, size: newPageSize })}
      />
    </MainContent>
  );
};

export default Admins;
