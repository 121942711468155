//@ts-nocheck
import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableBody, TableCell, TableRow, IconButton, Collapse } from '@material-ui/core';

import { newFormatAmount, formatDate, formatAoShort, getCurrency, formatAmount } from 'helpers/formatters';
import { expectedPaymentsDataType } from 'hooks/api/useExpectedPayments';
import { tableBodyStyles } from 'theme/styles';
import Tooltip from 'components/Tooltip';
import claimsArrow from 'assets/img/claimsArrow.svg';

import { KeyTransactions } from '../TransactionsTable/data';
import { useUserContext } from 'contexts/UserProvider';
import Target from './Target';
import styles from './styles.module.scss';

interface DataType {
  amount: number;
  name: string;
  currency: string;
  id: string;
  onClickTooltip?: () => void;
}

interface ResultType {
  thirdCell: React.ReactNode;
  id: string;
  thirdCellClassNames: string;
  needBottomBorder?: boolean;
  firstCell?: React.ReactNode;
  secondCell?: React.ReactNode;
}

const getResultAmount = (data: DataType[], isAdmin) => {
  const result: ResultType[] = [];

  data.forEach(({ amount, name, currency, onClickTooltip, id }) => {
    if (!Number.isNaN(amount) && amount !== 0) {
      result.push({
        thirdCell: (
          <>
            <span>
              {name}
              :&nbsp;
            </span>
            <span className={styles.thirdCellAmount} style={{ backgroundImage: "url('assets/img/claimsArrow.svg')" }}>
              {newFormatAmount(amount, currency)}
              {onClickTooltip && isAdmin && (
                <Tooltip tooltipWithoutArrow title="Pay off" styleTooltip={{ width: 'max-content' }}>
                  <img onClick={onClickTooltip} src={claimsArrow} alt="claimsArrow" className={styles.arrow} />
                </Tooltip>
              )}
            </span>
          </>
        ),
        id,
        thirdCellClassNames: classNames(onClickTooltip && styles.thirdCell),
      });
    }
  });

  result[result.length - 1] = { ...result[result.length - 1], needBottomBorder: true };

  return result;
};

type RowPropsType = {
  setDataForPopup: (any: any) => void;
  expectedPayment: expectedPaymentsDataType;
  isFirstTotal?: boolean;
};

export enum nameTargetEnum {
  USD = 'Total USD',
  EUR = 'Total EUR',
}

const Row: FC<RowPropsType> = ({ expectedPayment, isFirstTotal, setDataForPopup }: RowPropsType) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const extStyles = tableBodyStyles();
  const isTotal =
    expectedPayment.company_name === nameTargetEnum.EUR || expectedPayment.company_name === nameTargetEnum.USD;
  const TotalNameStyle = isTotal ? { fontWeight: 700 } : {};
  //@ts-ignore
  const isOverdue = !!expectedPayment.orders?.find((item) => item.status === 'overdue');
  //@ts-ignore
  const isGracePeriod = !!expectedPayment.orders?.find((item) => item.status === 'due');
  const { user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  const targetArray = () => {
    let result: ResultType[] = [];

    expectedPayment.orders.forEach(
      ({ commission, principal_amount, default_interest, order_title, order_number, order_id, currency }) => {
        const resultAmount = getResultAmount(
          [
            {
              amount: Number(commission),
              name: t('tables.expectedPaymentsTable.commission'),
              currency,
              onClickTooltip: () =>
                setDataForPopup({
                  firstCellContent: order_title,
                  secondCellContent: t('tables.expectedPaymentsTable.commission'),
                  thirdCellContent: `${getCurrency(currency)}${formatAmount(Number(commission))}`,
                  total: commission,
                  factoring_company_id: expectedPayment.company_id,
                  factoring_company_name: expectedPayment.company_name,
                  factoring_order_id: order_id,
                  factoring_order_number: order_number,
                  companyId: expectedPayment.company_id,
                  currency,
                  category: KeyTransactions.INTERNAL_COMMISSION,
                }),
              id: `${order_id}-commission`,
            },
            {
              amount: Number(principal_amount),
              name: t('tables.expectedPaymentsTable.principalAmount'),
              currency,
              onClickTooltip: () =>
                setDataForPopup({
                  firstCellContent: order_title,
                  secondCellContent: t('tables.expectedPaymentsTable.principalAmount'),
                  thirdCellContent: `${getCurrency(currency)}${formatAmount(Number(principal_amount))}`,
                  total: principal_amount,
                  factoring_company_id: expectedPayment.company_id,
                  factoring_company_name: expectedPayment.company_name,
                  factoring_order_id: order_id,
                  factoring_order_number: order_number,
                  companyId: expectedPayment.company_id,
                  currency,
                  category: KeyTransactions.INTERNAL_PRINCIPAL_AMOUNT,
                }),
              id: `${order_id}-principalAmount`,
            },
            {
              amount: Number(default_interest),
              name: t('tables.expectedPaymentsTable.defaultInterest'),
              currency,
              onClickTooltip: () =>
                setDataForPopup({
                  firstCellContent: order_title,
                  secondCellContent: t('tables.expectedPaymentsTable.defaultInterest'),
                  thirdCellContent: `${getCurrency(currency)}${formatAmount(Number(default_interest))}`,
                  total: default_interest,
                  factoring_company_id: expectedPayment.company_id,
                  factoring_company_name: expectedPayment.company_name,
                  factoring_order_id: order_id,
                  factoring_order_number: order_number,
                  companyId: expectedPayment.company_id,
                  currency,
                  category: KeyTransactions.INTERNAL_DEFAULT_INTEREST,
                }),
              id: `${order_id}-defaultInterest`,
            },
          ],
          isAdmin,
        );

        if (isTotal) {
          result = resultAmount;

          return;
        }

        const AmountCell = (
          <>
            <span className={styles.secondText}>
              {t('tables.expectedPaymentsTable.activationOrder')}
              &nbsp;
            </span>
            <span>{order_title.slice(3)}</span>
          </>
        );

        const [firstResultAmount, ...anotherResultAmount] = resultAmount;
        result = [
          ...result,
          {
            ...firstResultAmount,
            secondCell: AmountCell,
          },
          ...anotherResultAmount,
        ];
      },
    );

    return result;
  };

  return (
    <>
      <TableRow
        className={classNames(styles.row, {
          [styles.total]: isFirstTotal,
          [styles.overdue]: isOverdue,
          [styles.gracePeriod]: isGracePeriod,
        })}
        onClick={() => setOpen(!open)}
      >
        <TableCell align="left" width="35%" className={styles.cell}>
          {/* @ts-ignore */}
          {formatDate(expectedPayment.payment_date)}
        </TableCell>
        <TableCell
          align="left"
          width="35%"
          className={classNames(styles.fixedCell, extStyles.nameWidth, styles.cell)}
          style={TotalNameStyle}
        >
          {!isTotal && expectedPayment.company_name}
        </TableCell>
        <TableCell align="right" className={styles.cell}>
          <div
            style={{ ...TotalNameStyle, textAlign: 'right' }}
            className={classNames({ [styles.totalCell]: isTotal })}
          >
            {isTotal && <span className={styles.totalCategory}>{`${expectedPayment.company_name}:`}</span>}
            {/* @ts-ignore */}
            <span>{newFormatAmount(Number(expectedPayment.total), expectedPayment.orders[0]?.currency)}</span>
          </div>
        </TableCell>
        <TableCell width={28} align="center" className={styles.cell}>
          <IconButton aria-label="expand row" size="small" className={styles.button}>
            <span className={classNames(styles.icon, open ? styles.closeIcon : styles.openIcon)} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.collapseCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box my="" ml="0px">
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {targetArray().map(({ id, ...props }) => (
                    <Target {...props} key={id} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
