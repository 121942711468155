//@ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import { Button, DownloadIcon, Pagination, RangePicker, Select, Table, Modal } from '@gamesb42/ui-kit';
import { useLocation, useParams, Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import Flex from 'components/Flex';
import { formatAmount, formatDate, getCurrencyWithCode } from 'helpers/formatters';
import { getToken } from 'helpers/token';
import { AmountTransactionsType, ItemTransactionsType } from 'hooks/api/useTransaction';
import { getFileFA } from 'components/forms/CompanyForm/reportsData';
import endpoints from 'consts/endpoints';
import ActionsButton from '../General/ActionsButton';
import TableWrapper from 'components/TableWrapper';
import { useUserContext } from 'contexts/UserProvider';

import s from './style.module.scss';

export default function ({ type, activationOrderData, deleteActivationOrder, title, setStatus }) {
  const { activationOrderId } = useParams();
  const [auditData, setAuditData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [recordId, setRecordId] = useState('');
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const query: QueryParams = queryString.parse(history.location.search, { parseNumbers: true });
  let { limit = 10, page = 0 } = query;
  const [recordData, setRecordData] = useState([]);
  const { user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  const columns = [
    {
      title: 'Date',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return moment(record.createdAt, moment.ISO_8601).format('DD.MM.YYYY, HH:mm');
      },
    },
    {
      title: 'Author',
      align: 'left' as 'left',
      render: (_: any, record: any) => {
        return (
          <Link to={`/users/${record.userId}`} style={{ color: '#7145EC', textDecorationLine: 'underline' }}>
            {record.userName}
          </Link>
        );
      },
    },
    {
      title: 'Section',
      align: 'left' as 'left',
      render: (record) => `${record.objectType} - ${record.objectName}`,
    },
  ];

  const recordColumns = [
    {
      title: 'Before',
      align: 'left' as 'left',
      render: (_, record) => {
        return (
          <div>
            {record.key}: <span style={{ color: '#E02A61' }}>{JSON.stringify(record.old)}</span>
          </div>
        );
      },
    },
    {
      title: 'After',
      align: 'left' as 'left',
      render: (_, record) => {
        return (
          <div>
            {record.key}: <span style={{ color: '#0E9C1C' }}>{JSON.stringify(record.new)}</span>
          </div>
        );
      },
    },
  ];

  const getAuditLog = () => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    fetch(
      `${process.env.REACT_APP_LOG_URL}/auditlog/api/v1/log_records/object/${activationOrderId}?page=${page}&page_size=${limit}`,
      {
        method: 'GET',
        headers: myHeaders,
      },
    )
      .then((response) => response.json())
      .then((result) => setAuditData(result))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const onChangeQuery = useCallback(
    (newValues: Partial<QueryParams>) => {
      history.replace(
        `${history.location.pathname}?${queryString.stringify(
          { limit, page, ...newValues },
          { skipEmptyString: true },
        )}`,
      );
    },
    [history, limit, page],
  );

  useEffect(() => {
    getAuditLog();
  }, [limit, page]);

  useEffect(() => {
    if (recordId.length > 0) {
      try {
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${getToken()}`);

        fetch(`${process.env.REACT_APP_LOG_URL}/api/v1/log_records/${recordId}`, {
          method: 'GET',
          headers: myHeaders,
        })
          .then((response) => response.json())
          .then((result) => {
            const preparedData = Object.entries(result.delta_flat).reduce((acc, curr, i) => {
              acc.push({ key: curr[0], old: curr[1].old, new: curr[1].new });
              return acc;
            }, []);
            setRecordData(preparedData);
          })
          .catch(() => {})
          .finally(() => setLoading2(false));
      } catch (e) {}
    }
  }, [recordId]);

  return (
    <>
      <Flex direction="column" gap="12px" style={{ position: 'relative' }}>
        {isAdmin && (
          <ActionsButton
            type={type}
            deleteActivationOrder={deleteActivationOrder}
            id={activationOrderData?.id}
            title={title}
            companyId={activationOrderData?.companyId}
            setStatus={setStatus}
            currency={activationOrderData?.currency}
            amount={activationOrderData?.amount}
            status={activationOrderData?.status}
          />
        )}
      </Flex>
      <TableWrapper>
        <Table
          onRow={({ id }) => ({
            onClick: () => {
              setRecordId(id);
              setOpen(true);
            },
          })}
          columns={columns}
          dataSource={auditData}
          initialHeight="800px"
          loading={isLoading}
        />
        <Pagination
          total={auditData.length}
          current={(page + 1) as number}
          pageSize={limit as number}
          onChange={(newCurrent: number, newPageSize: number) =>
            onChangeQuery({ page: newCurrent - 1, limit: newPageSize })
          }
        />
      </TableWrapper>
      <Modal
        open={open}
        title="Change history"
        onOk={() => setOpen(false)}
        okButtonProps={{
          onMouseDown: () => {},
        }}
        okText="OK"
        onCancel={() => {
          setOpen(false);
        }}
        width="768px"
      >
        <Table loading={loading2} style={{ cursor: 'pointer' }} columns={recordColumns} dataSource={recordData} />
      </Modal>
    </>
  );
}
