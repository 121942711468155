// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RangePicker, Button, UploadIcon } from '@gamesb42/ui-kit';

import useCompany, { useCompanyType, listPdsAccountType, statusRevenueUploadFileDataType } from 'hooks/api/useCompany';
import DateInput from 'components/inputs/DateInput';
import { RefreshContext } from 'contexts/RefreshContext';
import setUrlStringParams from 'helpers/setUrlStringParams';
import { revenueByDays } from 'consts/tableFilters';
import { formatDateIsoShort } from 'helpers/formatters';
import { DropdownKit, DropdownSizeEnum } from 'components/uiKit/DropdownKit';
// import Button from 'components/uiKit/Button';
import SwitcherButton from 'components/uiKit/SwitcherButton';

import { CompanyTypeParams } from '../index';

import DropZoneWithTitleButton from './DropZoneWithTitleButton/DropZoneWithTitleButton';
import UpdatedAndProcessedFilePopUp from './UpdatedAndProcessedFilePopUp/UpdatedAndProcessedFilePopUp';

import { listCurrency } from '.';
import { useUserContext } from 'contexts/UserProvider';

const dailyRevenueKey = 'dailyRevenueKey';
const uploadSvg = require('assets/img/uploadRevenue.svg').default;

interface PropsT {
  listPdsAccounts: listPdsAccountType;
  requestParams: CompanyTypeParams;
  currency: string;
  setCurrency: (data: string) => void;
}
const HeaderDailyRevenue: FC<PropsT> = ({
  requestParams: { date_from = '', date_to = '', stores_ids = '' },
  listPdsAccounts,
  currency,
  setCurrency,
}) => {
  const { getRevenueByDays, uploadRevenueFile, getStatusRevenueUploadFile }: useCompanyType = useCompany();
  const refreshContext = useContext(RefreshContext);
  const { companyId } = useParams();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<statusRevenueUploadFileDataType | null>(null);

  const [currentCurrencyIndex, setCurrentCurrencyIndex] = useState(0); // 0 = EUR, 1 = USD
  const [period, setPeriod] = useState<DatePickerProps['value']>(undefined); // out format 2024-10-19
  const { logOut, user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  useEffect(() => {
    const sessionParams = refreshContext.getNewParams({ datakey: dailyRevenueKey });

    if (sessionParams) {
      setUrlStringParams({ filters: revenueByDays, currentScreenParams: { ...sessionParams } });
    }
  }, []);

  useEffect(() => {
    companyId && getRevenueByDays({ company_id: companyId, currency });
  }, [companyId]);

  const setParamsClaims = (value, key) => {
    if (key === 'period' && value) {
      date_from = value[0].format('YYYY-MM-DD');
      date_to = value[1].format('YYYY-MM-DD');
    }

    if (key === 'period' && !value) {
      date_from = '';
      date_to = '';
    }

    const params = { date_from, date_to, stores_ids, [key]: value || '' };

    refreshContext.setParams({ datakey: dailyRevenueKey, params });
    setUrlStringParams({
      filters: revenueByDays,
      currentScreenParams: params,
    });
    companyId && currency && getRevenueByDays({ company_id: companyId, currency, params });
  };

  return (
    <>
      <UpdatedAndProcessedFilePopUp
        onClose={() => {
          setUploadStatus(null);
        }}
        uploadStatus={uploadStatus}
        callback={(status) => {
          setUploadStatus(null);

          if (status === 'withErrors') {
            setIsOpen(true);
          }
        }}
      />
      <DropZoneWithTitleButton
        titlePopUp={t('forms.companies.agreements.uploadRevenueReport')}
        titleButton={t('forms.companies.agreements.uploadRevenueReport')}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        callback={(file) => {
          if (file.length === 0 || !companyId) return;
          const formData = new FormData();
          formData.append('csv_file', file[0]);
          const getStatusUploadFunc = (taskId) =>
            getStatusRevenueUploadFile({
              id: taskId,
              successCallback: (res) => {
                if (res.state !== 'SUCCESS' && res.state !== 'FAILURE' && res.state !== 'REVOKED') {
                  getStatusUploadFunc(taskId);

                  return;
                }
                setIsOpen(false);
                setUploadStatus(res);
              },
            });
          uploadRevenueFile({
            company_id: companyId,
            body: formData,
            successCallback: ({ taskId }) => getStatusUploadFunc(taskId),
          });
        }}
      />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', marginBottom: 12, justifyContent: 'space-between', gap: '12px' }}>
          <SwitcherButton
            style={{ height: 40, marginBottom: 12 }}
            list={[
              {
                title: 'EUR',
              },
              {
                title: 'USD',
              },
            ]}
            current={currentCurrencyIndex}
            callback={(e) => {
              const value = e === 0 ? 'EUR' : 'USD';
              setCurrentCurrencyIndex(e);
              setCurrency(value);
              companyId &&
                currency &&
                getRevenueByDays({
                  company_id: companyId,
                  currency: value,
                  params: { date_from, date_to, stores_ids },
                });
            }}
          />
          <RangePicker
            label="Selected period"
            size="medium"
            style={{ marginBottom: '12px', width: '260px' }}
            value={period}
            onChange={(e) => {
              setPeriod(e);
              setParamsClaims(e, 'period');
            }}
          />
          <DropdownKit
            label={t('forms.companies.agreements.revenueSource')}
            options={listPdsAccounts.items || []}
            keyObj="storeName"
            value={
              (listPdsAccounts?.items && listPdsAccounts.items.find(({ storeId }) => storeId === stores_ids)) || ''
            }
            changeSelectItem={(_, { storeId }) => setParamsClaims(storeId, revenueByDays[2])}
            wrapStyle={{ width: 218, marginRight: 20 }}
            positionType={DropdownSizeEnum.SIZE_40}
          />
        </div>
        {isAdmin && (
          <Button
            type="primary"
            // style={{ height: 40, width: 245 }}
            startAdornment={<img className="dropZoneImg" src={uploadSvg} alt="upload" />}
            onClick={() => setIsOpen(true)}
            icon={<UploadIcon size={16} />}
          >
            Upload
          </Button>
        )}
      </div>
    </>
  );
};

export default HeaderDailyRevenue;
