// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, notification } from '@gamesb42/ui-kit';
import TabContext from '@material-ui/lab/TabContext';

import useSystemSetting from 'hooks/api/useSystemSetting';
import useErrorCallback from 'hooks/useErrorCallback';
import { RefreshContext } from 'contexts/RefreshContext';
import useValidateSettingsForm from 'hooks/useValidateSettingsForm';
import PlatformPanel from 'components/forms/SystemSettingsForm/PlatformPanel';
import { UseStylingButton } from 'hooks/useStylingForMaterialUi';
import useRouteTitle from 'hooks/useRouteTitle';
import { tableSettingsStyles } from 'theme/styles';
import { emailValidator, phoneValidator } from 'helpers/validators';
import { updateTitle } from 'helpers/common';

import RecalculatePage from './RecalculatePage';
import SignatureTabPanel from './SignatureTabPanel';
import AuthTabPanel from './AuthTabPanel';
import GeneralPage from './GeneralPage';
import JustControlItPanel from './JustControlItPanel';
import CompanyInfoTabPanel from './CompanyInfoTabPanel';
import GoogleApiCredentialsTab from './GoogleApiCredentialsTab';
import NotificationsPanel, { NatificationTabGroupDataType } from './NotificationsPanel';
import ImapPanel, { VatTabGroupDataType } from './ImapPanel';
import BankApiPanel from './BankApiPanel';
import { NameEnum, SystemFieldsEnum } from './SystemSettingsFormData';
import { SystemSettingsEnum, SystemSettingsPath } from './data';

const initialSystemSettingsData = {
  signature: {
    position: '',
    full_name: '',
    factor_name: '',
  },
  auth: {
    enabled: false,
    server_url: '',
    client_id: '',
    realm_name: '',
  },
  accounts: {
    enabled: false,
    server_url: '',
    aliases: {
      INTERESTS: '',
      BANK_FEE: '',
      PENALTY: '',
      CONV_IN: '',
      CONV_OUT: '',
      INCOME: '',
      OUTCOME: '',
      EMISSION: '',
    },
  },
  general: {
    investor_interest: '',
    grace_period: '',
    customer_contract_template: '',
    auto_debiting: false,
  },
  justcontrolit: {
    host: '',
    password: '',
    username: '',
  },
  company_info: {
    legal_name: '',
    trading_name: '',
    company_registration: '',
    tax_number: '',
    website: '',
    address: '',
    city: '',
    country: '',
    address2: '',
    zip: '',
    director_full_name: '',
    director_title: '',
    representative_full_name: '',
    representative_title: '',
    representative_email: '',
    representative_phone_number: '',
    beneficiary_usd: '',
    bank_usd: '',
    swift_usd: '',
    iban_usd: '',
    bank_address_usd: '',
    beneficiary_bank_usd: '',
    reference_usd: '',
    beneficiary_eur: '',
    bank_eur: '',
    swift_eur: '',
    iban_eur: '',
    bank_address_eur: '',
  },
  google_api: {
    private_key: '',
    client_email: '',
    gsuite_domain: '',
  },
  // days_to_keep - this is the period during which we store processed emails, and then delete
  imap: {
    enabled: false,
    host: '',
    port: '',
    login: '',
    password: '',
    days_to_keep: '',
  } as VatTabGroupDataType,
};

const SystemSettingsForm: FC = () => {
  const styles = useStyles();
  const classes = tableSettingsStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { title } = useRouteTitle();
  const { systemSettingsData, getSystemSettings, getSystemSettingsIsFetching, editSystemSettings } = useSystemSetting();
  const { errorCallback } = useErrorCallback(({ errorField, errorCallbackText }) => {
    errorCallbackView({ errorField, errorCallbackText });
  });
  const errorCallbackView = useCallback(({ errorField, errorCallbackText }) => {
    if (Object.keys(formError).includes(errorField)) {
      setFormError((prevState) => ({
        ...prevState,
        [errorField]: { status: true, text: errorCallbackText },
      }));

      return false;
    }

    return true;
  }, []);

  const { submenuPath = 'general' } = useParams();
  const submenuTranslate = (translatedPath) => {
    switch (translatedPath) {
      case SystemSettingsPath.KEYCLOAK:
        return SystemSettingsEnum.AUTH;
      case SystemSettingsPath.DISTRIBUTION_PLATFORMS:
        return SystemSettingsEnum.PLATFORM;
      case SystemSettingsPath.FACTOR_DETAILS:
        return SystemSettingsEnum.COMPANY_INFO;
      default:
        return translatedPath;
    }
  };

  const submenu = submenuTranslate(submenuPath);
  const refreshContext = useContext(RefreshContext);
  const [currentTab, setCurrentTab] = useState(submenu);
  const [systemSettingsFormData, setSystemSettingsFormData] = useState(initialSystemSettingsData);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [formError, setFormError] = useState({});
  const compareSimpleObjects = (formData, oldFormData) => JSON.stringify(formData) === JSON.stringify(oldFormData);

  const { errors } = useValidateSettingsForm(systemSettingsFormData.company_info);
  const stylingButton = UseStylingButton();

  const successCallback = useCallback(() => {
    setButtonDisabled(true);
    notification.success({
      message: 'Settings saved',
    });
  }, [history, getSystemSettings, errorCallback]);

  useEffect(() => {
    if (systemSettingsData) {
      setSystemSettingsFormData((prev) => ({
        ...prev,
        [submenu]: { ...systemSettingsData },
      }));
    }
  }, [systemSettingsData]);

  useEffect(() => {
    setCurrentTab(submenu && submenu.length > 0 ? submenu : 'general');

    const titleForBrowser = (submenu[0].toUpperCase() + submenu.slice(1)).replaceAll('_', ' ');
    updateTitle(titleForBrowser === 'Auth' ? 'Keycloak' : titleForBrowser);
  }, [submenu]);

  useEffect(() => {
    ![SystemSettingsEnum.PLATFORM, SystemSettingsEnum.RECALCULATE].includes(submenu) &&
      getSystemSettings({ block_name: submenu, errorCallback });
    refreshContext.add({ func: getSystemSettings, params: { submenu, errorCallback } });
  }, [getSystemSettings, submenu, refreshContext, errorCallback]);

  useEffect(() => {
    setButtonDisabled(compareSimpleObjects(systemSettingsFormData, systemSettingsData));
  }, [systemSettingsFormData, systemSettingsData]);

  useEffect(
    () => () => {
      refreshContext.clear();
    },
    [refreshContext],
  );

  const setFormValue = useCallback(
    (groupName) => (e: React.ChangeEvent<HTMLInputElement>, columnName?: string, columnIndex?: number) => {
      if (columnName && columnName === 'text_content') {
        return;
      }

      if (columnName !== undefined && columnIndex !== undefined) {
        setSystemSettingsFormData((prev) => {
          const templates = [...prev[groupName].templates];
          templates[columnIndex] = { ...templates[columnIndex] };
          templates[columnIndex][columnName] = e.target.value;

          return {
            ...prev,
            [groupName]: { ...prev[groupName], templates },
          };
        });

        return;
      }

      if (Object.keys(systemSettingsFormData.accounts.aliases).includes(e.target.name)) {
        setSystemSettingsFormData((prev) => ({
          ...prev,
          [groupName]: {
            ...prev[groupName],
            aliases: { ...prev.accounts.aliases, [e.target.name]: e.target.value },
          },
        }));
      } else if (e.target.name === 'auto_debiting') {
        setSystemSettingsFormData((prev) => ({
          ...prev,
          general: { ...prev.general, auto_debiting: e.target.checked },
        }));
      } else if (e.target.name === 'enabled') {
        setSystemSettingsFormData((prev) => ({
          ...prev,
          [groupName]: { ...prev[groupName], enabled: e.target.checked },
        }));
      } else if (e.target.name === NameEnum.ADDRESSES) {
        setSystemSettingsFormData((prev) => ({
          ...prev,
          [groupName]: {
            ...prev[groupName],
            addresses: e.target.value.split('\n'),
          },
        }));
      } else {
        setSystemSettingsFormData((prev) => ({
          ...prev,
          [groupName]: { ...prev[groupName], [e.target.name]: e.target.value },
        }));
      }

      if (e.target.name === SystemFieldsEnum.representative_email) {
        errors[e.target.name].isError = !emailValidator(e.target.value);
        errors[e.target.name].errorText = errors[e.target.name].isError
          ? e.target.value
            ? t('errors.emailNotValid')
            : t('errors.required')
          : '';
      } else if (e.target.name === SystemFieldsEnum.representative_phone_number) {
        errors[e.target.name].isError =
          !phoneValidator(e.target.value) && e.target.value !== '' && e.target.value !== null;
        errors[e.target.name].errorText = errors[e.target.name].isError
          ? e.target.value
            ? t('errors.phoneNotValid')
            : t('errors.required')
          : '';
      } else if (errors[e.target.name]) {
        errors[e.target.name].isError = e.target.value === '' || e.target.value === null;
        errors[e.target.name].errorText = errors[e.target.name]?.isError ? t('errors.required') : '';
      } else return;
      setFormError(errors);
    },
    [setSystemSettingsFormData, systemSettingsFormData, errors],
  );

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setFormError({});

      if (currentTab === 'company_info') {
        if (errors.isFormValid) {
          editSystemSettings({
            formData: { ...systemSettingsFormData[submenu] },
            block_name: submenu,
            resultKey: 'systemSettingsData',
            successCallback,
            errorCallback,
          });
        } else {
          setFormError(errors);
        }
      } else {
        editSystemSettings({
          formData: { ...systemSettingsFormData[submenu] },
          block_name: submenu,
          resultKey: 'systemSettingsData',
          successCallback,
          errorCallback,
        });
      }
    },
    [systemSettingsFormData, editSystemSettings, successCallback, errorCallback, currentTab, errors],
  );

  if (getSystemSettingsIsFetching) {
    return null;
  }

  const isShowSaveButton = ![SystemSettingsEnum.PLATFORM, SystemSettingsEnum.RECALCULATE].includes(submenu);

  return (
    <form className={styles.root} onSubmit={onSubmit}>
      <div className={classes.titleSettings}>{title}</div>
      <div className={styles.table}>
        <TabContext value={currentTab}>
          <div>
            {submenu === SystemSettingsEnum.GENERAL && (
              <GeneralPage groupData={systemSettingsFormData.general} setValue={setFormValue('general')} />
            )}
            {submenu === SystemSettingsEnum.GOOGLE_API && (
              <GoogleApiCredentialsTab
                groupData={systemSettingsFormData.google_api}
                setValue={setFormValue('google_api')}
              />
            )}
            {submenu === SystemSettingsEnum.SIGNATURE && (
              <SignatureTabPanel groupData={systemSettingsFormData.signature} setValue={setFormValue('signature')} />
            )}
            {submenu === SystemSettingsEnum.AUTH && (
              <AuthTabPanel groupData={systemSettingsFormData.auth} setValue={setFormValue('auth')} />
            )}
            {submenu === SystemSettingsEnum.JUSTCONTROLIT && (
              <JustControlItPanel
                groupData={systemSettingsFormData.justcontrolit}
                setValue={setFormValue('justcontrolit')}
              />
            )}
            {submenu === SystemSettingsEnum.COMPANY_INFO && (
              <CompanyInfoTabPanel
                groupData={systemSettingsFormData.company_info}
                setValue={setFormValue('company_info')}
                errors={formError}
              />
            )}
            {submenu === SystemSettingsEnum.PLATFORM && <PlatformPanel />}
          </div>
        </TabContext>
      </div>
      {isShowSaveButton && (
        <div className={styles.footerBlock}>
          <Button htmlType="submit" type="primary">
            {t('global.saveS')}
          </Button>
        </div>
      )}
      {submenu === SystemSettingsEnum.RECALCULATE && <RecalculatePage errorCallback={errorCallback} />}
    </form>
  );
};

export default SystemSettingsForm;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 650,
    marginTop: 22,
    overflowX: 'auto',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    '& header': {
      boxShadow: 'none',
    },
  },
  footerBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 500,
  },
}));
