//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { PageHeader, Input, Select, Button } from '@gamesb42/ui-kit';
import { Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import MainContent from 'components/MainContent';
import { User, UserKeyEnum } from 'types/UserTypes';
import useLoadCompanies from 'hooks/useLoadCompanies';
import { getUser, createUser, editUser, deleteUser } from 'api/users';
import urls from 'constants/urls';
import Flex from 'components/Flex';
import ActionButtonCard from './ActionButtonCard';

const AdminCard = ({ match: { params }, history: { goBack, push } }: RouteComponentProps<{ userId?: string }>) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { userId } = params;
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser(userId!)
      .then((user) => {
        setUser(user);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [userId]);

  return (
    <>
      {!loading && (
        <MainContent type="form" title={`${user?.last_name} ${user?.first_name}`}>
          <PageHeader
            title={`${user?.last_name} ${user?.first_name}`}
            back
            rightContent={<ActionButtonCard name={`${user?.last_name} ${user?.first_name}`} role={user?.role} />}
          />

          <div style={{ background: '#FFF' }}>
            <Flex align="center">
              <div
                style={{
                  padding: '16px 8px 16px 16px',
                  width: '200px',
                  color: '#686F88',
                  fontFamily: 'Muller',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                Admin name:
              </div>
              <div style={{ color: '#090909', fontWeight: '400' }}>
                {user?.last_name} {user?.first_name}
              </div>
            </Flex>

            <Flex align="center">
              <div
                style={{
                  padding: '16px 8px 16px 16px',
                  width: '200px',
                  color: '#686F88',
                  fontFamily: 'Muller',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                Email:
              </div>
              <div style={{ color: '#090909', fontWeight: '400' }}>{user?.email}</div>
            </Flex>
            <Flex align="center">
              <div
                style={{
                  padding: '16px 8px 16px 16px',
                  width: '200px',
                  color: '#686F88',
                  fontFamily: 'Muller',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                Role:
              </div>
              <div style={{ color: '#090909', fontWeight: '400' }}>{user?.role}</div>
            </Flex>
          </div>
        </MainContent>
      )}
    </>
  );
};

export default AdminCard;
