import React, { useEffect, useState } from 'react';
import { PageHeader, Input, Select, Button } from '@gamesb42/ui-kit';
import { Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import MainContent from 'components/MainContent';
import { User, UserKeyEnum } from 'types/UserTypes';
import useLoadCompanies from 'hooks/useLoadCompanies';
import { getUser, createUser, editUser, deleteUser } from 'api/users';
import urls from 'constants/urls';
import { getFactorCompany } from 'api/company';

const CreateEditAdmin = ({
  match: { params },
  history: { goBack, push },
}: RouteComponentProps<{ userId?: string }>) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const { userId } = params;
  const [factorCompanyId, setFactorCompanyId] = useState<string>();

  const isCreateUser = !userId;
  const title = isCreateUser ? 'Create new Admin' : 'Edit admin';

  const onFinish = ({ company, ...other }: Omit<User, 'company'> & { company: string[] }) => {
    const api = isCreateUser ? createUser : editUser;
    setLoading(true);

    api({ ...other, company_id: factorCompanyId }, userId as string)
      .then(() => {
        setLoading(false);
        push(urls.getUsers());
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isCreateUser) {
      getUser(userId)
        .then((user) => {
          form.setFieldsValue({
            [UserKeyEnum.FIRST_NAME]: user[UserKeyEnum.FIRST_NAME],
            [UserKeyEnum.LAST_NAME]: user[UserKeyEnum.LAST_NAME],
            [UserKeyEnum.EMAIL]: user[UserKeyEnum.EMAIL],
            [UserKeyEnum.ROLE]: user[UserKeyEnum.ROLE],
          });
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }

    if (isCreateUser) {
      setLoading(false);
    }
  }, [form, userId, isCreateUser]);

  useEffect(() => {
    getFactorCompany().then((company) => {
      setFactorCompanyId(company.id);
      setLoading(false);
    });
  }, []);

  return (
    <MainContent type="form" title={title}>
      <PageHeader title={title} back />
      <Form form={form} disabled={loading} initialValues={{ [UserKeyEnum.COMPANY]: [] }} onFinish={onFinish}>
        <Form.Item
          name={UserKeyEnum.FIRST_NAME}
          rules={[
            {
              required: true,
              message: `Please input ${t('users.firstName')}!`,
            },
          ]}
        >
          <Input label={t('users.firstName')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.LAST_NAME}
          rules={[
            {
              required: true,
              message: `Please input ${t('users.lastName')}!`,
            },
          ]}
        >
          <Input label={t('users.lastName')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.EMAIL}
          rules={[
            {
              required: true,
              message: `Please input ${t('global.email')}!`,
            },
            { type: 'email', message: 'Please input correct email!' },
          ]}
        >
          <Input label={t('global.email')} size="large" />
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.ROLE}
          rules={[
            {
              required: true,
              message: `Please input role!`,
            },
          ]}
        >
          <Select label="Role" size="large">
            <Select.Option value="factor-admin">Factor Admin</Select.Option>
            <Select.Option value="factor-aml">Factor AML</Select.Option>
            <Select.Option value="factor-viewer">Factor Viewer</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={UserKeyEnum.PASSWORD}
          rules={[
            {
              required: true,
              message: `Please input ${t('global.password')}!`,
            },
            {
              min: 8,
              pattern: /^.*(?=.{8,32})(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()-+_=]).*$/,
              message: 'At least 8 characters: upper and lower case Latin letters, special characters',
            },
          ]}
        >
          <Input label={t('global.password')} size="large" />
        </Form.Item>

        {/* <Space size="large"> */}
        <Button type="primary" htmlType="submit">
          {t('global.save')}
        </Button>
        {/* </Space> */}
      </Form>
    </MainContent>
  );
};

export default CreateEditAdmin;
