import React, { FC, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import pencilSvg from 'assets/img/newPencil.svg';
import ViewGeneralActions from 'components/common/ViewGeneralActions';
import Switcher from 'components/uiKit/Switcher';
import { Switch } from '@gamesb42/ui-kit';
import Flex from 'components/Flex';
import { QueryParams } from '.';

interface Props {
  isChecked: boolean;
  setIsChecked: (data: boolean) => void;
}

const ActionButton: FC<Props> = ({ isChecked, setIsChecked }) => {
  const history = useHistory();
  const query = queryString.parse(history.location.search, { parseNumbers: true });

  return (
    <ViewGeneralActions
      customContents={[
        () => (
          <Flex gap="8px" style={{ width: '214px' }}>
            <Switch
              checked={isChecked}
              size="medium"
              onChange={(e) => {
                setIsChecked(e);
                history.replace(
                  `${history.location.pathname}?${queryString.stringify(
                    { ...query, isArchive: e },
                    { skipEmptyString: true },
                  )}&roles=factor-admin&roles=factor-viewer&roles=factor-aml`,
                );
              }}
            />
            <div>Show Deleted only</div>
          </Flex>
        ),
      ]}
    />
  );
};

export default ActionButton;
