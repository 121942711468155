//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, DownloadIcon, Pagination, RangePicker, Select, Table } from '@gamesb42/ui-kit';
import { useLocation, useParams } from 'react-router-dom';
import { moment } from 'moment';

import Flex from 'components/Flex';
import { formatAmount, formatDate, getCurrencyWithCode } from 'helpers/formatters';
import { getToken } from 'helpers/token';
import { getSymbolAndColorTransactions, capitalizeFirstLetter, isInRange } from './helpers';
import { AmountTransactionsType, ItemTransactionsType } from 'hooks/api/useTransaction';
import { getFileFA } from 'components/forms/CompanyForm/reportsData';
import endpoints from 'consts/endpoints';
import ActionsButton from '../General/ActionsButton';
import { useUserContext } from 'contexts/UserProvider';

import s from './style.module.scss';

type TrunsactionsT = {
  type: AoTypeEnum;
  activationOrderData: activationOrderDataType;
  deleteActivationOrder: (data: deleteActivationOrderDataType) => void;
  title: string;
  setStatus?: any;
};

export default function ({ type, activationOrderData, deleteActivationOrder, title, setStatus }: TrunsactionsT) {
  const [currencyFilter, setCurrencyFilter] = useState('All');
  const [typeFilter, setTypeFilter] = useState('All');
  const [datesFilter, setDatesFilter] = useState(['', '']);

  //@ts-ignore
  const { activationOrderId } = useParams();
  const [transactionData, setTransactionData] = useState([]);
  const [filtertData, setFilterData] = useState([]);
  const { logOut, user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';

  const columns = [
    {
      title: 'Date',
      dataIndex: 'value_datetime',
      key: 'value_datetime',
      render: (date: string) => formatDate(date),
      sorter: (a, b) => {
        try {
          const date1 = new Date(a.value_datetime);
          const date2 = new Date(b.value_datetime);

          if (date1 > date2) {
            return 1;
          } else if (date1 < date2) {
            return -1;
          } else {
            return 0;
          }
        } catch (e) {}
      },
    },
    { title: 'Type', dataIndex: 'type', key: 'type', render: (type: string) => capitalizeFirstLetter(type) },
    { title: 'Details', dataIndex: 'details', key: 'details' },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (amount: AmountTransactionsType, record: ItemTransactionsType) => {
        const { symbol, color } = getSymbolAndColorTransactions(amount?.amount, record.type);

        return (
          <span style={{ color }}>
            {`${symbol} ${getCurrencyWithCode(Number(amount.currency))}${formatAmount(Math.abs(amount?.amount || 0))}`}
          </span>
        );
      },
    },
  ];

  const getTransactions = () => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${getToken()}`);

    fetch(
      `${process.env.REACT_APP_TS_URL}/v1/transactions/` +
        '?page=1&size=100' +
        `&filter=%257B%2522property%2522%253A%2520%2522factoring_order_id%2522%252C%2522` +
        `operator%2522%253A%2520%2522%253D%2522%252C%2520%2522value%2522%253A%2520%2522` +
        activationOrderId +
        `%2522%257D`,
      {
        method: 'GET',
        headers: myHeaders,
      },
    )
      .then((response) => response.json())
      .then((result) => {
        setTransactionData(result.items);
        setFilterData(result.items);
      })
      .catch((error) => console.error(error));
  };

  const handleDownload = () => {
    const prepareFilter = () => {
      let filter = [{ property: 'factoring_order_id', operator: '=', value: activationOrderId }];

      if (currencyFilter && currencyFilter !== 'All') {
        filter = [...filter, { property: 'currency', operator: '=', value: currencyFilter }];
      }

      if (typeFilter && typeFilter !== 'All') {
        filter = [...filter, { property: 'type', operator: '=', value: typeFilter }];
      }

      if (datesFilter && datesFilter[0] && datesFilter[1]) {
        filter = [
          ...filter,
          { property: 'value_datetime', operator: '>=', value: datesFilter[0].format('YYYY-MM-DD') },
          { property: 'value_datetime', operator: '<=', value: datesFilter[1].format('YYYY-MM-DD') },
        ];
      }

      return filter;
    };

    getFileFA(
      endpoints.downloadArchiveTransactionURL({
        format: 'xlsx',
        ...(true && { filter: JSON.stringify(prepareFilter()) }),
        ...(false && { sort }),
      }),
      undefined,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const applyFilters = (currencyFilterValue, typeFilterValue, datesFilterValue) => {
    const filtered = transactionData.filter(
      (el) =>
        (currencyFilterValue === 'All' || el.amount.currency === currencyFilterValue) &&
        (typeFilterValue === 'All' || el.type.includes(typeFilterValue)) &&
        (!Array.isArray(datesFilterValue) ||
          isInRange([datesFilterValue[0]?.toDate(), datesFilterValue[1]?.toDate()], new Date(el.value_datetime))),
    );

    setFilterData(filtered);
  };

  return (
    <Flex direction="column" gap="12px" style={{ position: 'relative' }}>
      {isAdmin && (
        <ActionsButton
          type={type}
          deleteActivationOrder={deleteActivationOrder}
          id={activationOrderData?.id}
          title={title}
          companyId={activationOrderData?.companyId}
          setStatus={setStatus}
          currency={activationOrderData?.currency}
          amount={activationOrderData?.amount}
          status={activationOrderData?.status}
        />
      )}
      <Flex className={s.actionBar} justify="space-between">
        <Flex gap="12px">
          <RangePicker
            label="Selected period"
            size="middle"
            style={{ width: '343px' }}
            onChange={(e) => {
              setDatesFilter(e);
              applyFilters(currencyFilter, typeFilter, e);
            }}
          />
          <Select
            label="Amount"
            options={[
              { label: 'All', value: 'All' },
              { label: 'EUR', value: '978' },
              { label: 'USD', value: '840' },
            ]}
            size="middle"
            style={{ width: '200px' }}
            value={currencyFilter}
            onChange={(e) => {
              setCurrencyFilter(e);
              applyFilters(e, typeFilter, datesFilter);
            }}
          />
          <Select
            label="Type"
            options={[
              { label: 'All', value: 'All' },
              { label: 'Incoming', value: 'incoming' },
              { label: 'Outgoing', value: 'outgoing' },
              { label: 'Internal', value: 'internal' },
              { label: 'Fee', value: 'fee' },
              { label: 'Adjustment', value: 'adjustment' },
              { label: 'Conversion', value: 'conversion' },
            ]}
            size="middle"
            style={{ width: '200px' }}
            value={typeFilter}
            onChange={(e) => {
              setTypeFilter(e);
              applyFilters(currencyFilter, e, datesFilter);
            }}
          />
        </Flex>
        <Button icon={<DownloadIcon size={16} />} onClick={handleDownload} />
      </Flex>

      <Table columns={columns} dataSource={filtertData} initialHeight="800px" />
    </Flex>
  );
}
