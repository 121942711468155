import React, { useState } from 'react';

import s from './workingCapital.module.scss';
import { DatePicker, InputAmount, InputNumber, Input, Button, DatePickerProps, Select } from '@gamesb42/ui-kit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import urls from 'constants/urls';
import { formatPercent } from 'helpers/formatters';

export default function CreateWorkingCapital() {
  const { t } = useTranslation();
  const history = useHistory();
  const [issueDate, setIssueDate] = useState<DatePickerProps['value']>(null);
  const [repaymentDate, setRepaymentDate] = useState<DatePickerProps['value']>(null);
  const [amount, setAmount] = useState(null);
  const [interest, setInterest] = useState<any>(null);
  const [comment, setComment] = useState('');
  const [currency, setCurrency] = useState();
  const [counterparty, setCounterparty] = useState();
  const [description, setDescription] = useState();

  const handleCreate = async () => {
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('X-AuthToken')}`);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      name: 'string',
      receipt_date: issueDate?.format('YYYY-MM-DD'),
      return_date: repaymentDate?.format('YYYY-MM-DD'),
      amount,
      currency,
      comment,
      interest,
      counterparty,
      loan_description: description,
    });

    await fetch(`${process.env.REACT_APP_BASE_URL}/tranche`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }).catch(() => {});
  };

  return (
    <div className={s.contentWrapper}>
      <div className={s.createForm}>
        <div className={s.title}>Create new tranche</div>

        <div className={s.row}>
          <DatePicker
            value={issueDate}
            onChange={setIssueDate}
            style={{ width: '343px' }}
            label={'Issue date'}
            size="large"
            format="DD.MM.YYYY"
          />
        </div>

        <div className={s.row}>
          <div style={{ width: '343px' }}>
            <Select size="large" value={currency} label="Currency" onChange={setCurrency}>
              <Select.Option value="EUR">EUR</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          </div>
          <InputAmount
            value={amount}
            //@ts-ignore
            onChange={(e) => setAmount(e)}
            style={{ width: '343px' }}
            label="Amount"
            currency={currency}
            size="large"
          />
        </div>

        <div className={s.row}>
          <DatePicker
            value={repaymentDate}
            onChange={setRepaymentDate}
            style={{ width: '343px' }}
            label={'Repayment date'}
            size="large"
            format="DD.MM.YYYY"
          />
          <InputNumber
            value={interest}
            onChange={(e) => {
              setInterest(e);
            }}
            style={{ width: '343px' }}
            label={'Interest,%'}
            size="large"
            min={0}
            max={100}
            precision={1}
          />
        </div>

        <div className={s.row}>
          <Input
            value={counterparty}
            onChange={
              //@ts-ignore
              (e) => setCounterparty(e.target.value)
            }
            style={{ width: '718px', height: '60px' }}
            label={'Counterparty'}
            placeholder="Counterparty"
            disabled={false}
          />
        </div>

        <div className={s.row}>
          <Input.TextArea
            value={description}
            onChange={
              //@ts-ignore
              (e) => setDescription(e.target.value)
            }
            style={{ width: '718px', height: '100px' }}
            label={'Loan description'}
            placeholder="Loan description"
            disabled={false}
          />
        </div>

        <div className={s.row}>
          <Input.TextArea
            value={comment}
            onChange={
              //@ts-ignore
              (e) => setComment(e.target.value)
            }
            style={{ width: '718px', height: '100px' }}
            label={'Comment'}
            placeholder="Comment"
            disabled={false}
          />
        </div>

        <div className={s.footer}>
          <Button
            onClick={async () => {
              await handleCreate();
              history.push(urls.getWorkingCapital() + '?order=desc&sortedBy=receiptDate');
            }}
            className={s.save}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              history.push(urls.getWorkingCapital() + '?order=desc&sortedBy=receiptDate');
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
