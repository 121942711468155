const TOKEN = 'X-AuthToken';
const REFRESH_TOKEN = 'refreshToken';
const USER = 'user';

const setToken = (value: string) => localStorage.setItem(TOKEN, value);
const getToken = () => localStorage.getItem(TOKEN);

const setRefreshToken = (value: string) => localStorage.setItem(REFRESH_TOKEN, value);
const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

const setUser = (value: string) => localStorage.setItem(USER, value);
const getUser = () => localStorage.getItem(USER);
const getUserRole = () => {
  return JSON.parse(getUser()!)?.role;
};
const isAdmin = getUserRole() === 'factor-admin';
const isAML = getUserRole() === 'factor-aml';

export { setToken, getToken, setRefreshToken, getRefreshToken, setUser, getUser, getUserRole, isAdmin, isAML };
