// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Button } from '@gamesb42/ui-kit';

import { getFileFA } from 'components/forms/CompanyForm/reportsData';
import { getToken } from 'helpers/token';
import { createCompanyAgreement } from 'api/company';
import { useUserContext } from 'contexts/UserProvider';
import EditModal from './EditModal';

import styles from './styles.module.scss';

import pencilSvg from 'assets/img/newPencil.svg';
import downloadSvg from 'assets/img/download.svg';

function renderStatus(status) {
  switch (status) {
    case 'sent':
      return (
        <div
          style={{
            display: 'flex',
            width: '54px',
            height: '24px',
            borderRadius: '4px',
            background: '#9FA5B9',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
          }}
        >
          SENT
        </div>
      );
    case 'created':
      return (
        <div
          style={{
            display: 'flex',
            width: '80px',
            height: '24px',
            borderRadius: '4px',
            background: '#95B3FF',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
          }}
        >
          CREATED
        </div>
      );
    case 'signed':
      return (
        <div
          style={{
            display: 'flex',
            width: '146px',
            height: '24px',
            borderRadius: '4px',
            background: '#95B3FF',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
          }}
        >
          SIGNED BY CLIENT
        </div>
      );
    case 'delivered':
      return (
        <div
          style={{
            display: 'flex',
            width: '100px',
            height: '24px',
            borderRadius: '4px',
            background: '#95B3FF',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
          }}
        >
          DELIVERED
        </div>
      );
    case 'completed':
      return (
        <div
          style={{
            display: 'flex',
            width: '106px',
            height: '24px',
            borderRadius: '4px',
            background: '#32D141',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
          }}
        >
          COMPLETED
        </div>
      );
    default:
      return '-';
  }
}

const FA = ({
  companyId,
  setCompanyData,
  setCompanyStatus,
}: {
  companyId: string;
  setCompanyData: any;
  setCompanyStatus: any;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [status, setStatus] = useState('-');
  const [btn1Loading, setBtn1Loading] = useState(false);
  const [btn2Loading, setBtn2Loading] = useState(false);
  const { user } = useUserContext();
  const isAdmin = user?.role === 'factor-admin';
  const [open, setOpen] = useState(false);

  const columns = [
    {
      title: 'Factoring Agreement No.',
      dataIndex: 'file_name',
      width: '248px',
      align: 'left' as 'left',
      render: (text: string) => <span style={{ color: '#7145EC', textDecoration: 'underline' }}>{text}</span>,
    },
    {
      title: 'DocuSign status',
      dataIndex: 'status',
      width: '279px',
      align: 'left' as 'left',
      render: () => renderStatus(status),
    },
    {
      title: '',
      dataIndex: '',
      align: 'left' as 'left',
      render: (row) => {
        if (isAdmin) {
          return (
            <div style={{ display: 'flex', height: '100%', gap: '32px', alignItems: 'center' }}>
              <Button
                style={{
                  visibility: row.status === 'created' ? 'visible' : 'hidden',
                  width: '132px',
                  padding: 0,
                  justifyContent: 'center',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                loading={btn1Loading}
                onClick={handleGeneratNew}
              >
                Generate new
              </Button>
              <Button
                style={{
                  visibility: row.status === 'created' ? 'visible' : 'hidden',
                  width: '119px',
                  padding: 0,
                  justifyContent: 'center',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                loading={btn2Loading}
                onClick={handleSendToSign}
                type="primary"
              >
                Send to sign
              </Button>
              <Tooltip title="Edit">
                <img
                  style={{ cursor: 'pointer', width: 18 }}
                  onClick={(e) => {
                    // handleDownload();
                    setOpen(true);
                  }}
                  src={pencilSvg}
                />
              </Tooltip>
              <Tooltip title="Download">
                <img
                  style={{ cursor: 'pointer', width: 18 }}
                  onClick={(e) => {
                    handleDownload();
                  }}
                  src={downloadSvg}
                />
              </Tooltip>
            </div>
          );
        } else {
          return (
            <div
              style={{ display: 'flex', height: '100%', gap: '32px', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <Tooltip title="Download">
                <img
                  style={{ cursor: 'pointer', width: 18 }}
                  onClick={(e) => {
                    handleDownload();
                  }}
                  src={downloadSvg}
                />
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];

  function getFAData() {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${getToken()}`);

    fetch(`${process.env.REACT_APP_BASE_URL}/agreement/company/${companyId}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => {
        if (!result?.detail) {
          setData(result);
        }

        setStatus(result.status);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getFAData();
  }, []);

  function handleGeneratNew() {
    setBtn1Loading(true);
    return createCompanyAgreement(companyId)
      .then((data) => setCompanyData(data))
      .then(() => {
        getFAData();
      })
      .catch(() => {})
      .finally(() => {
        setBtn1Loading(false);
      });
  }

  function handleSendToSign() {
    setBtn2Loading(true);
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${getToken()}`);

    fetch(`${process.env.REACT_APP_BASE_URL}/agreement/${data.agreement_id}/sent_to_sign`, {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        setStatus(result.status);
        setCompanyStatus('signing');
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setBtn2Loading(false);
      });
  }

  async function handleDownload() {
    getFileFA(`${process.env.REACT_APP_BASE_URL}/agreement/${data.agreement_id}/file`);
  }

  return (
    <div className={styles.wrapper}>
      <Table loading={isLoading} columns={columns} dataSource={Object.keys(data).length > 0 ? [data] : []} />
      <EditModal open={open} setOpen={setOpen} agreementId={data?.agreement_id} />
    </div>
  );
};

export default FA;
